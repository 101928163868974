import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import countries from '#Constants/countries';
import { Container, SuggestionsContainer, SuggestionsContentContainer, SuggestionRow, } from '#Pages/NewCheckout/components/shared/Dropdown';
import FormInput from '#Pages/NewCheckout/components/shared/FormInput/FormInput';
import useOnclickOutside from 'react-cool-onclickoutside';
const Country = ({ title, errorText, handleSelect, country, description }) => {
    const [filter, setFilter] = useState(country);
    const [suggestions, setSuggestions] = useState([]);
    const memoizedCountries = useMemo(() => countries.map(co => ({ key: co.isoCode, value: co.name })), [countries]);
    const ref = useOnclickOutside(() => {
        setSuggestions([]);
    });
    const onSelect = (item) => {
        handleSelect(item);
        setFilter(item);
        setSuggestions([]);
    };
    useEffect(() => {
        if (country !== filter) {
            setSuggestions(memoizedCountries.filter(item => item.value.toLowerCase().startsWith(filter.toLowerCase())));
        }
    }, [filter]);
    const renderSuggestions = () => suggestions.map(suggestion => {
        return (React.createElement(SuggestionRow, { key: suggestion.key, onClick: () => onSelect(suggestion.value) }, suggestion.value));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { ref: ref },
            React.createElement(FormInput, { errorText: errorText, name: title || '', onChange: setFilter, value: country || filter, description: description }),
            !!suggestions.length && (React.createElement(SuggestionsContainer, null,
                React.createElement(SuggestionsContentContainer, null, renderSuggestions()))))));
};
export default observer(Country);
