import './credentials.scss';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { ButtonsBlock, FormInput, FormInputPhone } from '#Components';
import useEnterClick from '#Hooks/useEnterClick';
import useStores from '#Hooks/useStores';
import { defaultErrorsObj, UAevents } from '#Types/commonTypes';
import { Events, trackEvent } from '#Utils/amplitudeAnalyticsService';
import postMessageToWebflow from '#Utils/postMessageWebflow';
import { validateCredentials } from '#Utils/validators';
const CredentialsForm = ({ isRequired }) => {
    const [errors, setErrors] = useState(defaultErrorsObj);
    const { quoteData: { setDetails, details, setPromo, promoCode, sendData, services }, appData: { error }, } = useStores();
    const onNextClick = () => {
        const formErrors = validateCredentials(details);
        const isAnyError = Object.values(formErrors).some(msg => !!msg);
        if (isRequired && details && !isAnyError) {
            const eventKeys = services.map(key => UAevents[key]);
            sendData();
            postMessageToWebflow('nextClick', 'click');
            postMessageToWebflow('submitClick', 'submit', eventKeys);
            // amplitude-analytics
            trackEvent(Events.CONTACT_DETAILS_SUBMITTED);
            return;
        }
        setErrors(formErrors);
        setTimeout(() => {
            setErrors(defaultErrorsObj);
        }, 4000);
        // amplitude-analytics
        trackEvent(Events.CONTACT_DETAILS_ERROR);
    };
    useEnterClick(onNextClick);
    useEffect(() => {
        // amplitude-analytics
        trackEvent(Events.CONTACT_DETAILS_OPENED);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "credentials" },
            React.createElement("div", { className: "credentials__form-wrapper" },
                React.createElement("form", { className: "credentials__form" },
                    React.createElement(FormInput, { type: "email", ariaLabel: "email", errorText: errors.email, description: "We'll use this email to send you your quote breakdown.", name: "Email", onChange: email => setDetails({ ...details, email }), value: details.email }),
                    React.createElement(FormInputPhone, { ariaLabel: "phone", errorText: errors.phone, description: "We'll use this number to call you and confirm your order details.", name: "Phone number", onChange: phone => setDetails({ ...details, phone: phone.replace(/[^0-9]/g, '') }), onPrefixChange: phonePrefix => setDetails({ ...details, phonePrefix }), value: details.phone, type: "tel" }),
                    React.createElement(FormInput, { ariaLabel: "name", errorText: errors.firstName, name: "First name", onChange: firstName => setDetails({ ...details, firstName }), value: details.firstName }),
                    React.createElement(FormInput, { ariaLabel: "surname", errorText: errors.lastName, name: "Last name", onChange: lastName => setDetails({ ...details, lastName }), value: details.lastName }),
                    React.createElement(FormInput, { ariaLabel: "promo", description: "If you don't have any \u2014 please leave it blank.", name: "Promo code", onChange: txt => setPromo(txt), value: promoCode }))),
            error && React.createElement("span", { className: "credentials__error" }, error)),
        React.createElement(ButtonsBlock, { onNextClick: onNextClick })));
};
export default observer(CredentialsForm);
