import useStores from '#Hooks/useStores';
import { DiscountFromTerm, collectionCostOfPlans, storageSpaces, } from '#Types/newQuoteTypes';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FlexItemsContainer, Subtitle, Title, TitleClarify } from '../../shared/Components';
import StoragePlanItem from './StoragePlanItem';
import ModuleError from '../../shared/ModuleError';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { isMobile } from 'react-device-detect';
const StoragePlan = ({ clarify = true }) => {
    const { checkoutData: { setStorageSpace, storageSpace, storageTerm, isPersonStudent, isStudentPromo }, errorState: { isNoStoragePlanError, setEmptyStoragePlanError }, } = useStores();
    const onStorageSpaceSelect = (k) => {
        setStorageSpace(k);
        setEmptyStoragePlanError();
    };
    const getPriceWithDiscount = (price, key) => {
        const discount = (isPersonStudent || isStudentPromo) && storageTerm === 'monthly'
            ? 10
            : storageTerm
                ? DiscountFromTerm[storageTerm]
                : 0;
        const discountPrice = price - (price * discount) / 100;
        return discountPrice;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null,
            `How much space do you need?`,
            clarify && React.createElement(TitleClarify, null, "For storage")),
        React.createElement(Subtitle, null, 'Please note that we do not provide self-access to our units. If storing for longer periods, you will be invoiced every 4 weeks.'),
        React.createElement(ModuleError, { opened: isNoStoragePlanError }, "Please select storage plan"),
        isMobile ? (React.createElement(ScrollMenu, { LeftArrow: null, RightArrow: null }, Object.entries(storageSpaces).map(([key, card]) => (React.createElement(StoragePlanItem, { imageUrl: card.iconUrl, title: card.title, priceWithDiscount: getPriceWithDiscount(card.price, key) === card.price
                ? undefined
                : getPriceWithDiscount(card.price, key), price: card.price, size: card.sizes, sqft: card.sqft, key: key, onClick: () => onStorageSpaceSelect(key), isChecked: storageSpace === key }))))) : (React.createElement(FlexItemsContainer, null, Object.entries(storageSpaces).map(([key, card]) => (React.createElement(StoragePlanItem, { imageUrl: card.iconUrl, title: card.title, priceWithDiscount: getPriceWithDiscount(card.price, key) === card.price
                ? undefined
                : getPriceWithDiscount(card.price, key), price: card.price, size: card.sizes, sqft: card.sqft, key: key, onClick: () => onStorageSpaceSelect(key), isChecked: storageSpace === key }))))),
        storageSpace && (React.createElement("span", { style: { display: 'block', marginTop: 16, fontSize: 15.5 } }, `Average collection price starting from £${collectionCostOfPlans[storageSpace]}`))));
};
export default observer(StoragePlan);
