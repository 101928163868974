import { CleaningOneBed, CleaningThreeBed, CleaningTwoBed, StorageSpace3x5, StorageSpace6x4, StorageSpace6x6, StorageSpace8x6, StorageSpace9x8, StorageSpace12x8, StorageSpaceOther, } from '#Images';
import { CleaningIcon, MovingIcon, ShippingIcon, StorageIcon } from '../components/icons';
export var ServiceKeys;
(function (ServiceKeys) {
    ServiceKeys["MOVING"] = "MOVING";
    ServiceKeys["STORAGE"] = "STORAGE";
    ServiceKeys["SHIPPING"] = "SHIPPING";
    ServiceKeys["CLEANING"] = "CLEANING";
})(ServiceKeys || (ServiceKeys = {}));
export const monthsToTermKey = {
    1: 'monthly',
    3: '3_months',
    6: '6_months',
};
export const StorageTermKeysToTerm = {
    //amount of weeks
    monthly: 4,
    '3_months': 12,
    '6_months': 24,
};
export const cleaningMatching = {
    '1bed': 'one_bedroom',
    '2bed': 'two_bedroom',
    '3bed': 'three_bedroom',
    custom: 'custom',
};
export const storageMatching = {
    '15': 'wardrobe',
    '24': 'small_room',
    '36': 'studio_flat',
    '48': 'half_of_one_car_garage',
    '72': 'small_office',
    '96': 'standard_one_car_garage',
    Custom: 'avg_house',
};
export const mainServices = {
    MOVING: {
        iconUrl: MovingIcon,
        title: 'Moving',
    },
    STORAGE: {
        iconUrl: StorageIcon,
        title: 'Storage',
    },
    CLEANING: {
        iconUrl: CleaningIcon,
        title: 'Cleaning',
    },
    SHIPPING: {
        iconUrl: ShippingIcon,
        title: 'Shipping',
    },
};
export const StorageSizes = {
    wardrobe: 'Plan 3x5',
    small_room: 'Plan 6x4',
    studio_flat: 'Plan 6x6',
    half_of_one_car_garage: 'Plan 8x6',
    small_office: 'Plan 9x8',
    standard_one_car_garage: 'Plan 12x8',
    avg_house: 'Custom',
};
export const cleaningPrices = {
    one_bedroom: {
        title: '1-bedroom or smaller',
        price: '£136',
        cleaners: 1,
        hours: 5,
        extraFee: 27,
        iconUrl: CleaningOneBed,
    },
    two_bedroom: {
        title: '2-bedroom flat',
        price: '£272',
        cleaners: 2,
        hours: 5,
        extraFee: 45,
        iconUrl: CleaningTwoBed,
    },
    three_bedroom: {
        title: '3-bedroom flat',
        price: '£326',
        cleaners: 2,
        hours: 6,
        extraFee: 54,
        iconUrl: CleaningThreeBed,
    },
    custom: {
        title: 'Custom',
        price: '',
        cleaners: 0,
        hours: 0,
        extraFee: 0,
        description: 'WE ALSO ACCOMMODATE LARGER PROPERTIES',
        iconUrl: StorageSpaceOther,
    },
};
export const storageTerms = {
    monthly: { discount: 0, term: 0, title: 'Monthly' },
    '3_months': { discount: 25, term: 0, title: '3 months' },
    '6_months': { discount: 40, term: 0, title: '6 months' },
};
export const collectionCostOfPlans = {
    wardrobe: '£96',
    small_room: '£149',
    studio_flat: '£149',
    half_of_one_car_garage: '£149',
    small_office: '£196',
    standard_one_car_garage: '£196',
    avg_house: '£342',
};
export const storageSpaces = {
    wardrobe: {
        iconUrl: StorageSpace3x5,
        title: 'Wardrobe',
        price: '£14.99',
        sizes: '5FT X 3 FT X 8 FT',
        sqft: '15',
    },
    small_room: {
        iconUrl: StorageSpace6x4,
        title: 'Small room',
        price: '£22',
        sizes: '6FT X 4 FT X 8 FT',
        sqft: '24',
    },
    studio_flat: {
        iconUrl: StorageSpace6x6,
        title: 'Studio flat',
        price: '£29',
        sizes: '6FT X 6 FT X 8 FT',
        sqft: '36',
    },
    half_of_one_car_garage: {
        iconUrl: StorageSpace8x6,
        title: 'Half of 1-Car Garage',
        price: '£39',
        sizes: '8FT X 6 FT X 8 FT',
        sqft: '48',
    },
    small_office: {
        iconUrl: StorageSpace9x8,
        title: 'Small Office',
        price: '£58',
        sizes: '9FT X 8 FT X 8 FT',
        sqft: '72',
    },
    standard_one_car_garage: {
        iconUrl: StorageSpace12x8,
        title: 'Standard 1-Car Garage',
        price: '£79',
        sizes: '12FT X 8 FT X 8 FT',
        sqft: '96',
    },
    avg_house: {
        iconUrl: StorageSpaceOther,
        title: 'Avg. House +',
        price: '£0.82',
        sizes: '',
        sqft: 'Custom',
    },
};
