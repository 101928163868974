import React from 'react';
import PageContainer from './PageContainer';
import styled from 'styled-components';
import StepsBlock from './StepsBlock';
import ButtonsBlock from './ButtonsBlock/ButtonsBlock';
import AddNewService from './AddNewService';
import { isMobile } from 'react-device-detect';
import { Logo } from '#Images';
const MainContainer = styled.div `
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 16px;
`;
export const Header = styled.div `
  display: flex;
  img {
    margin-right: 240px;
  }
  margin-top: 24px;
  padding-bottom: 14px;
  margin-bottom: 44px;

  @media (max-width: 640px) {
    margin-bottom: 28px;
  }
`;
const Container = styled.div `
  display: flex;
  justify-content: space-between;

  @media (max-width: 640px) {
    flex-direction: column-reverse;
    justify-content: auto;
  }
`;
const RightContainer = styled.div `
  display: flex;
  flex-direction: column;

  @media (max-width: 640px) {
    flex-direction: row;
    justify-content: center;
  }
`;
const QuoteWrapper = ({ children, isFinalSlide }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(MainContainer, null,
            React.createElement(Header, null,
                React.createElement("a", { href: "https://www.stackt.co.uk/" },
                    React.createElement("img", { src: Logo, alt: "logo" }))),
            React.createElement(Container, null,
                React.createElement(PageContainer, null,
                    children,
                    React.createElement(ButtonsBlock, null)),
                !isFinalSlide && (React.createElement(RightContainer, null,
                    React.createElement(StepsBlock, null),
                    !isMobile && React.createElement(AddNewService, null)))))));
};
export default QuoteWrapper;
