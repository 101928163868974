import './students-quote.scss';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { QuoteWrapper } from '#Components';
import { Final } from '#Components/slides';
import useStores from '#Hooks/useStores';
import StudentQuoteCredentials from '#Components/slides/Credentials/StudentQuoteCredentials';
import { parseClientIdFromPm, parseClientIdFromSearchString } from '#Utils/queryStrParsers';
import { parseUtmStudentsSearchStr } from '#Utils/parseUtmSearchStr';
import useIframePm from '#Hooks/useIframePm';
const StudentsQuote = () => {
    const { studentData: { details, setUtmParams, setGtmClientId, clientId }, quoteData: { setTotalSlides, currentSlide }, } = useStores();
    const slidesToRender = {
        credentials: {
            title: 'Leave your details and enjoy an exclusive 10% discount for students',
            component: React.createElement(StudentQuoteCredentials, { key: "credentials", isRequired: true }),
        },
        final: {
            title: `Thank you, ${details.firstName}! 🙌`,
            component: React.createElement(Final, { key: "final" }),
        },
    };
    const slidesMap = Object.entries(slidesToRender);
    const totalSlides = slidesMap.length;
    useEffect(() => {
        setTotalSlides(totalSlides);
    }, []);
    // utm --
    useEffect(() => {
        const utmMarks = parseUtmStudentsSearchStr(window.location.search);
        const gtm_client_id = parseClientIdFromSearchString(window.location.search);
        setUtmParams(utmMarks);
        setGtmClientId(gtm_client_id);
        return () => { };
    }, []);
    const parseId = (e) => {
        const id = parseClientIdFromPm(e);
        if (!clientId && id) {
            setGtmClientId(id);
        }
    };
    useEffect(() => {
        window.addEventListener('message', parseId);
        return () => {
            window.removeEventListener('message', parseId);
        };
    }, []);
    // --
    useIframePm();
    return (React.createElement("div", { className: "checkout-page " },
        React.createElement(QuoteWrapper, { quoteTitle: "Get Free Collection", currentSlide: currentSlide, slideTitle: slidesMap[currentSlide - 1][1].title, progress: Math.ceil((currentSlide / totalSlides) * 100) }, slidesMap.map(([, slide], index) => currentSlide === index + 1 && slide.component))));
};
export default observer(StudentsQuote);
