import './scss/index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { init } from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from '#Components/ErrorBoundary/ErrorBoundary';
import { API_KEY } from '#Constants/amplitudeAnalytics';
const isStage = process.env.REACT_APP_STAGE === 'staging';
const isProd = process.env.REACT_APP_STAGE === 'production';
const sentryInit = () => {
    Sentry.init({
        dsn: isStage
            ? 'https://c032c99e609e427ba75a9fa484056118@o4505153720287232.ingest.sentry.io/4505154087485440'
            : 'https://25f1789239ec4784adf48d9fbc8af9c1@o4505153720287232.ingest.sentry.io/4505154522382336',
        integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.Replay({
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0,
        // Session Replay
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
};
if (isStage || isProd) {
    sentryInit();
}
if (isProd) {
    init(API_KEY);
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(React.createElement(React.StrictMode, null,
    React.createElement(ErrorBoundary, null,
        React.createElement(BrowserRouter, null,
            React.createElement(App, null)))));
