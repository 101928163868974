import origins from '#Constants/origins';
import { getCurrentHeight } from '#Utils/helpers';
import postMessageToWebflow from '#Utils/postMessageWebflow';
import { useEffect, useState } from 'react';
function useIframePm() {
    const [id, setId] = useState('');
    const publishHeight = () => {
        const currentHeight = getCurrentHeight();
        const actualHeight = document.body.scrollHeight > document.body.offsetHeight ? document.body.scrollHeight : document.body.offsetHeight;
        if (Math.abs(actualHeight - currentHeight) > 20) {
            postMessageToWebflow('heightChange', 'frameHeight', [], { height: actualHeight, id });
        }
    };
    const parseIframeIdFromPm = (e) => {
        const { origin } = e;
        if (!origins.includes(origin)) {
            return null;
        }
        const data = typeof e.data === 'object' ? e.data : JSON.parse(e.data);
        if (data.type === 'iframeId') {
            return data.message;
        }
        return null;
    };
    const parseId = (e) => {
        const frameId = parseIframeIdFromPm(e);
        if (frameId) {
            setId(frameId);
        }
    };
    useEffect(() => {
        let intId;
        if (id) {
            intId = window.setInterval(() => {
                publishHeight();
            }, 300);
        }
        return () => {
            if (intId) {
                clearInterval(intId);
            }
        };
    }, [id]);
    useEffect(() => {
        window.addEventListener('message', parseId);
        return () => {
            window.removeEventListener('message', parseId);
        };
    }, []);
}
export default useIframePm;
