import React, { useEffect } from 'react';
import { Subtitle, Title } from '#Pages/NewCheckout/components/shared/Components';
import styled from 'styled-components';
import postMessageToWebflow from '#Utils/postMessageWebflow';
const Note = styled.p `
  font-size: 20px;
  line-height: 28px;

  @media (max-width: 640px) {
    font-size: 16px;
    line-height: 22px;
  }
`;
const Final = () => {
    useEffect(() => {
        setTimeout(() => {
            postMessageToWebflow('nextClick', 'click');
        }, 150);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null, "Thank you! We\u2019re processing your order"),
        React.createElement(Subtitle, null, "We aim to respond within 15 minutes, but during busy periods like holidays, promotions and special campaigns, or non-working hours*, we may take a little longer to get back to you."),
        React.createElement(Note, null,
            "* We're available:",
            React.createElement("br", null),
            "Monday to Friday, 08:00 \u2013 20:00",
            React.createElement("br", null),
            "Saturday to Sunday, 10:00 \u2013 18:00")));
};
export default Final;
