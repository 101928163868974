import React from 'react';
import { AffiliatesQuote, CheckOut, MoverQuote, NewCheckout, StudentsQuote } from '#Pages';
import { Route, Switch } from 'react-router-dom';
function App() {
    return (React.createElement("div", { className: "App" },
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: '/new' },
                React.createElement(NewCheckout, null)),
            React.createElement(Route, { exact: true, path: '/students-quote' },
                React.createElement(StudentsQuote, null)),
            React.createElement(Route, { exact: true, path: '/mover-quote' },
                React.createElement(MoverQuote, null)),
            React.createElement(Route, { exact: true, path: '/affiliates-quote' },
                React.createElement(AffiliatesQuote, null)),
            React.createElement(Route, { path: '/' },
                React.createElement(CheckOut, null)))));
}
export default App;
