import './button.scss';
import React from 'react';
import Spinner from '../Spinner/Spinner';
export var ButtonKinds;
(function (ButtonKinds) {
    ButtonKinds["primary"] = "primary";
    ButtonKinds["secondary"] = "secondary";
})(ButtonKinds || (ButtonKinds = {}));
const Button = ({ title, onClick, kind, isLoading, submit, errorText }) => (React.createElement(React.Fragment, null,
    React.createElement("button", { disabled: isLoading, className: `button ${kind === ButtonKinds.primary ? 'button_primary' : ''} ${submit ? 'submit-btn' : ''}`, onClick: onClick, type: submit ? 'submit' : 'button' }, isLoading ? React.createElement(Spinner, null) : title),
    errorText && React.createElement("p", null, errorText)));
export default Button;
