import { UTMKeys, UTMStudentKeys } from '#Types/commonTypes';
const parseUtmSearchStr = (str) => {
    const urlSearchParams = new URLSearchParams(str);
    const allParams = Object.fromEntries(urlSearchParams.entries());
    const params = Object.keys(UTMKeys).reduce((acc, key) => {
        acc[key] = allParams[key] || '';
        return acc;
    }, {});
    return params;
};
export const parseUtmStudentsSearchStr = (str) => {
    const urlSearchParams = new URLSearchParams(str);
    const allParams = Object.fromEntries(urlSearchParams.entries());
    const params = Object.keys(UTMStudentKeys).reduce((acc, key) => {
        acc[key] = allParams[key] || '';
        return acc;
    }, {});
    return params;
};
export default parseUtmSearchStr;
