import React from 'react';
import './checkbox.scss';
const CheckBox = ({ checked, onChange }) => {
    const onCheck = (e) => {
        onChange(e.target.checked);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { className: "checkbox", id: "checkbox", checked: checked, onChange: onCheck, type: "checkbox" }),
        React.createElement("label", { htmlFor: "checkbox" })));
};
export default CheckBox;
