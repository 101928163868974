import React from 'react';
import styled from 'styled-components';
const Container = styled.div `
  max-width: 722px;
  width: 100%;
`;
const PageContainer = ({ children }) => {
    return React.createElement(Container, null, children);
};
export default PageContainer;
