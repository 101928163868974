import React from 'react';
import { ColumnItemContainer, CrossedPrice, CardHeader, BoldFont, Price, SelectBtn, FlexRow, CardFooter, } from './Components';
const StoragePlanItem = ({ title, imageUrl, isChecked, onClick, price, size, sqft, priceWithDiscount, }) => {
    const currentPrice = priceWithDiscount === undefined ? price : priceWithDiscount;
    return (React.createElement(ColumnItemContainer, { onClick: onClick, isChecked: isChecked },
        React.createElement(CardHeader, { isChecked: isChecked },
            React.createElement(BoldFont, null, `${sqft} ft²`),
            React.createElement("img", { src: imageUrl, alt: "" })),
        React.createElement(CardFooter, null,
            React.createElement(FlexRow, null,
                React.createElement(BoldFont, { style: { marginBottom: 6 } }, title),
                React.createElement(Price, null, priceWithDiscount ? (React.createElement(React.Fragment, null,
                    React.createElement(CrossedPrice, null, `£${price}`),
                    " ",
                    `£${currentPrice.toFixed(2)}`)) : (React.createElement(React.Fragment, null,
                    "\u00A3",
                    currentPrice)))),
            React.createElement(FlexRow, null,
                React.createElement("span", { style: { marginBottom: 22 } }, size),
                React.createElement("span", null, "per week")),
            React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
                React.createElement(SelectBtn, { selected: isChecked }, isChecked ? 'Selected' : 'Select plan')))));
};
export default StoragePlanItem;
