import './credentials.scss';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { ButtonsBlock, FormInput, FormInputPhone } from '#Components';
import useEnterClick from '#Hooks/useEnterClick';
import useStores from '#Hooks/useStores';
import { defaultErrorsObj } from '#Types/commonTypes';
import { validateCredentials } from '#Utils/validators';
const AffiliateQuoteCredentials = ({ isRequired }) => {
    const [errors, setErrors] = useState(defaultErrorsObj);
    const { affiliateData: { setDetails, details, sendAffiliateData }, appData: { error }, } = useStores();
    const onNextClick = () => {
        const formErrors = validateCredentials(details);
        const isAnyError = Object.values(formErrors).some(msg => !!msg);
        if (isRequired && details && !isAnyError) {
            sendAffiliateData();
            return;
        }
        setErrors(formErrors);
        setTimeout(() => {
            setErrors(defaultErrorsObj);
        }, 4000);
    };
    useEnterClick(onNextClick);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "credentials" },
            React.createElement("div", { className: "credentials__form-wrapper" },
                React.createElement("form", { className: "credentials__form" },
                    React.createElement(FormInput, { ariaLabel: "name", errorText: errors.firstName, name: "First name*", onChange: firstName => setDetails({ ...details, firstName }), value: details.firstName }),
                    React.createElement(FormInput, { ariaLabel: "surname", errorText: errors.lastName, name: "Last name*", onChange: lastName => setDetails({ ...details, lastName }), value: details.lastName }),
                    React.createElement(FormInput, { ariaLabel: "title", name: "Title", onChange: title => setDetails({ ...details, title }), value: details.title }),
                    React.createElement(FormInput, { errorText: errors.job_title, ariaLabel: "occupation", name: "Job title*", onChange: job_title => setDetails({ ...details, job_title }), value: details.job_title }),
                    React.createElement(FormInput, { ariaLabel: "company", name: "Company name", onChange: companyName => setDetails({ ...details, companyName }), value: details.companyName }),
                    React.createElement(FormInput, { type: "email", ariaLabel: "email", errorText: errors.email, name: "Email*", onChange: email => setDetails({ ...details, email }), value: details.email }),
                    React.createElement(FormInputPhone, { ariaLabel: "phone", errorText: errors.phone, name: "Phone number*", onChange: phone => setDetails({ ...details, phone: phone.replace(/[^0-9]/g, '') }), onPrefixChange: phonePrefix => setDetails({ ...details, phonePrefix }), value: details.phone, type: "tel" }),
                    React.createElement(FormInput, { ariaLabel: "comment", description: "If you don't have any \u2014 please leave it blank.", name: "Comment", onChange: comment => setDetails({ ...details, comment }), value: details.comment }))),
            error && React.createElement("span", { className: "credentials__error" }, error)),
        React.createElement(ButtonsBlock, { onNextClick: onNextClick })));
};
export default observer(AffiliateQuoteCredentials);
