import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { ButtonsBlock, Dropdown } from '#Components/index';
import countries from '#Constants/countries';
import useEnterClick from '#Hooks/useEnterClick';
import useStores from '#Hooks/useStores';
import { Events, trackEvent } from '#Utils/amplitudeAnalyticsService';
import postMessageToWebflow from '#Utils/postMessageWebflow';
const Country = ({ isRequired }) => {
    const { quoteData: { country, setCountry, setNextSlide }, } = useStores();
    const onCountrySet = (c) => {
        setCountry(c);
        // amplitude-analytics
        trackEvent(Events.DELIVERY_COUNTRY_ENTERED);
    };
    const onNextClick = () => {
        if (isRequired && country) {
            setNextSlide();
            postMessageToWebflow('nextClick', 'click');
        }
        else {
            // amplitude-analytics
            trackEvent(Events.DELIVERY_COUNTRY_ERROR);
        }
    };
    useEnterClick(onNextClick);
    useEffect(() => {
        // amplitude-analytics
        trackEvent(Events.DELIVERY_COUNTRY_OPENED);
    }, []);
    const memoizedCountries = useMemo(() => countries.map(co => ({ key: co.isoCode, value: co.name })), [countries]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Dropdown, { selected: country, items: memoizedCountries, placeholder: "United Kingdom", onChange: onCountrySet }),
        React.createElement(ButtonsBlock, { onNextClick: onNextClick })));
};
export default observer(Country);
