import useStores from '#Hooks/useStores';
import { mainServices, ServiceKeys } from '#Types/newQuoteTypes';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { NewServiceButton } from './Components';
import { NewEvents, trackNewEvent } from '#Utils/amplitudeAnalyticsService';
const Container = styled.div `
  max-width: 226px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #cdd7e0;
  border-radius: 15px;
`;
const Title = styled.div `
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 12px;
`;
const Content = styled.div `
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 16px;
  max-width: 224px;
`;
const List = styled.ul `
  margin-top: 8px;
  margin-left: -30px;
  /* list-style-type: '∙'; */
  list-style-type: none;
  li {
    :not(:last-child) {
      margin-bottom: 4px;
    }
    ::before {
      content: '∙';
      font-weight: bold;
      display: inline-block;
      width: 1em;
    }
  }
`;
const ButtonContainer = styled.div `
  font-size: 16px;
`;
const AddNewService = () => {
    const { checkoutData: { services, setCurrentSlide, isStorage, setIsMovedFromService, currentSlide }, } = useStores();
    const onAddCLick = () => {
        setIsMovedFromService(true);
        setCurrentSlide('service');
        trackNewEvent(NewEvents.checkoutv2_add_a_service_button_clicked);
    };
    const remainServices = Object.entries(mainServices)
        .filter(([key, service]) => !services.includes(key) &&
        key !== ServiceKeys.SHIPPING &&
        key !== ServiceKeys.STORAGE_RETURN &&
        (isStorage ? key !== ServiceKeys.MOVING : true))
        .map(([key, item]) => React.createElement("li", { key: key }, item.title));
    return (React.createElement(React.Fragment, null, !!remainServices.length && currentSlide !== 'service' && (React.createElement(Container, null,
        React.createElement(Title, null, "Anything else?"),
        React.createElement(Content, null,
            "Choose from a range of our bespoke services to get exactly the kind of experience you want:",
            React.createElement(List, null, remainServices)),
        React.createElement(ButtonContainer, null,
            React.createElement(NewServiceButton, { onClick: onAddCLick }, "Add a service"))))));
};
export default observer(AddNewService);
