import { track } from '@amplitude/analytics-browser';
export var Events;
(function (Events) {
    Events["GET_A_QUOTE_OPENED"] = "GET_A_QUOTE_OPENED";
    Events["GET_A_QUOTE_ITEM_SELECTED"] = "GET_A_QUOTE_ITEM_SELECTED";
    Events["GET_A_QUOTE_ERROR"] = "GET_A_QUOTE_ERROR";
    Events["COLLECTION_POSTCODE_OPENED"] = "COLLECTION_POSTCODE_OPENED";
    Events["COLLECTION_POSTCODE_ENTERED"] = "COLLECTION_POSTCODE_ENTERED";
    Events["COLLECTION_POSTCODE_ERROR"] = "COLLECTION_POSTCODE_ERROR";
    Events["CLEANING_POSTCODE_OPENED"] = "CLEANING_POSTCODE_OPENED";
    Events["CLEANING_POSTCODE_ENTERED"] = "CLEANING_POSTCODE_ENTERED";
    Events["CLEANING_POSTCODE_ERROR"] = "CLEANING_POSTCODE_ERROR";
    Events["DELIVERY_POSTCODE_OPENED"] = "DELIVERY_POSTCODE_OPENED";
    Events["DELIVERY_POSTCODE_ENTERED"] = "DELIVERY_POSTCODE_ENTERED";
    Events["DELIVERY_POSTCODE_ERROR"] = "DELIVERY_POSTCODE_ERROR";
    Events["DELIVERY_COUNTRY_OPENED"] = "DELIVERY_COUNTRY_OPENED";
    Events["DELIVERY_COUNTRY_ENTERED"] = "DELIVERY_COUNTRY_ENTERED";
    Events["DELIVERY_COUNTRY_ERROR"] = "DELIVERY_COUNTRY_ERROR";
    Events["STORAGE_SPACE_OPENED"] = "STORAGE_SPACE_OPENED";
    Events["STORAGE_SPACE_SELECTED"] = "STORAGE_SPACE_SELECTED";
    Events["STORAGE_SPACE_ERROR"] = "STORAGE_SPACE_ERROR";
    Events["PROPERTY_TYPE_OPENED"] = "PROPERTY_TYPE_OPENED";
    Events["PROPERTY_TYPE_SELECTED"] = "PROPERTY_TYPE_SELECTED";
    Events["PROPERTY_TYPE_ERROR"] = "PROPERTY_TYPE_ERROR";
    Events["MOVING_DATE_OPENED"] = "MOVING_DATE_OPENED";
    Events["MOVING_DATE_SELECTED"] = "MOVING_DATE_SELECTED";
    Events["CLEANING_DATE_OPENED"] = "CLEANING_DATE_OPENED";
    Events["CLEANING_DATE_SELECTED"] = "CLEANING_DATE_SELECTED";
    Events["CONTACT_DETAILS_OPENED"] = "CONTACT_DETAILS_OPENED";
    Events["CONTACT_DETAILS_SUBMITTED"] = "CONTACT_DETAILS_SUBMITTED";
    Events["CONTACT_DETAILS_ERROR"] = "CONTACT_DETAILS_ERROR";
})(Events || (Events = {}));
export const MapKeysToItems = {
    CLEANING: 'Cleaning',
    MOVING: 'Moving',
    SHIPPING: 'Shipping',
    STORAGE: 'Storage',
};
export const getAnalyticSKeys = (keys) => keys.map(key => MapKeysToItems[key]);
export const getPCEnteredType = (kind) => {
    if (kind === 'collection') {
        return Events.COLLECTION_POSTCODE_ENTERED;
    }
    if (kind === 'cleaning') {
        return Events.CLEANING_POSTCODE_OPENED;
    }
    return Events.DELIVERY_POSTCODE_ENTERED;
};
export const getPCErrorType = (kind) => {
    if (kind === 'collection') {
        return Events.COLLECTION_POSTCODE_ERROR;
    }
    if (kind === 'cleaning') {
        return Events.CLEANING_POSTCODE_ERROR;
    }
    return Events.DELIVERY_POSTCODE_ERROR;
};
export const getPCOpenType = (kind) => {
    if (kind === 'collection') {
        return Events.COLLECTION_POSTCODE_OPENED;
    }
    if (kind === 'cleaning') {
        return Events.CLEANING_POSTCODE_OPENED;
    }
    return Events.DELIVERY_POSTCODE_OPENED;
};
export const getDateOpenedKey = (kind) => {
    if (kind === 'cleaningDate') {
        return Events.CLEANING_DATE_OPENED;
    }
    return Events.MOVING_DATE_OPENED;
};
export const getDateSelectedKey = (kind) => {
    if (kind === 'cleaningDate') {
        return Events.CLEANING_DATE_SELECTED;
    }
    return Events.MOVING_DATE_SELECTED;
};
export const trackEvent = (param, props) => {
    track(param, props);
};
export var NewEvents;
(function (NewEvents) {
    NewEvents["checkoutv2_only_moving_selected"] = "checkoutv2_only_moving_selected";
    NewEvents["checkoutv2_only_storage_selected"] = "checkoutv2_only_storage_selected";
    NewEvents["checkoutv2_stage1_opened"] = "checkoutv2_stage1_opened";
    NewEvents["checkoutv2_stage1_button_visible"] = "checkoutv2_stage1_button_visible";
    NewEvents["checkoutv2_stage1_next_button_clicked"] = "checkoutv2_stage1_next_button_clicked";
    NewEvents["checkoutv2_stage2_opened"] = "checkoutv2_stage2_opened";
    NewEvents["checkoutv2_stage2_button_visible"] = "checkoutv2_stage2_button_visible";
    NewEvents["checkoutv2_stage2_next_button_clicked"] = "checkoutv2_stage2_next_button_clicked";
    NewEvents["checkoutv2_stage3_opened"] = "checkoutv2_stage3_opened";
    NewEvents["checkoutv2_stage3_button_visible"] = "checkoutv2_stage3_button_visible";
    NewEvents["checkoutv2_stage3_next_button_clicked"] = "checkoutv2_stage3_next_button_clicked";
    NewEvents["checkoutv2_stage4_opened"] = "checkoutv2_stage4_opened";
    NewEvents["checkoutv2_stage4_button_visible"] = "checkoutv2_stage4_button_visible";
    NewEvents["checkoutv2_stage4_submit_button_clicked"] = "checkoutv2_stage4_submit_button_clicked";
    NewEvents["checkoutv2_add_a_service_button_clicked"] = "checkoutv2_add_a_service_button_clicked";
    NewEvents["checkoutv2_any_step_clicked"] = "checkoutv2_any_step_clicked";
    NewEvents["checkoutv2_add_a_service_after_comming_back_to_the_first_step"] = "checkoutv2_add_a_service_after_comming_back_to_the_first_step";
})(NewEvents || (NewEvents = {}));
export const slideToBtnClickEvent = {
    service: NewEvents.checkoutv2_stage1_next_button_clicked,
    plans: NewEvents.checkoutv2_stage2_next_button_clicked,
    dateAndTime: NewEvents.checkoutv2_stage3_next_button_clicked,
    contactDetails: NewEvents.checkoutv2_stage4_submit_button_clicked,
    final: '',
};
export const slideToBtnVisibleEvent = {
    service: NewEvents.checkoutv2_stage1_button_visible,
    plans: NewEvents.checkoutv2_stage2_button_visible,
    dateAndTime: NewEvents.checkoutv2_stage3_button_visible,
    contactDetails: NewEvents.checkoutv2_stage4_button_visible,
    final: '',
};
export const trackNewEvent = (param) => {
    track(param);
};
