import './storageSpace.scss';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ButtonsBlock, Carousel, StorageSpaceCard } from '#Components';
import useEnterClick from '#Hooks/useEnterClick';
import useStores from '#Hooks/useStores';
import { collectionCostOfPlans, storageSpaces } from '#Types/quoteTypes';
import { Events, trackEvent } from '#Utils/amplitudeAnalyticsService';
import postMessageToWebflow from '#Utils/postMessageWebflow';
const StorageSpace = ({ isRequired }) => {
    const [error, setError] = useState('');
    const { quoteData: { setStorageSpace, storageSpace, setNextSlide }, } = useStores();
    const onStorageSpaceSelect = (k) => {
        setStorageSpace(k);
        // amplitude-analytics
        trackEvent(Events.STORAGE_SPACE_SELECTED, { type: k });
    };
    const onNextClick = () => {
        if (isRequired && storageSpace) {
            setNextSlide();
            postMessageToWebflow('nextClick', 'click');
            return;
        }
        setError(`Please choose at least one storage plan`);
        setTimeout(() => {
            setError('');
        }, 4000);
        // amplitude-analytics
        trackEvent(Events.STORAGE_SPACE_ERROR);
    };
    useEnterClick(onNextClick);
    useEffect(() => {
        // amplitude-analytics
        trackEvent(Events.STORAGE_SPACE_OPENED);
    }, []);
    const storageCards = Object.entries(storageSpaces).map(([key, service]) => (React.createElement("div", { style: { display: 'flex' } },
        React.createElement(StorageSpaceCard, { size: service.sizes, price: service.price, key: key, title: service.title, imgUrl: service.iconUrl, onClick: () => onStorageSpaceSelect(key), isChecked: storageSpace === key, sqft: service.sqft }),
        React.createElement("div", { style: { width: 10, height: 360 } }))));
    const mountedStyle = {
        animation: 'inAnimation 250ms ease-in',
    };
    const unmountedStyle = {
        animation: 'outAnimation 270ms ease-out',
        animationFillMode: 'forwards',
    };
    const currentSlideIndex = Object.entries(storageSpaces).findIndex(([key, item]) => storageSpace === key);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "storage-space-slide" },
            React.createElement("h4", null, `Please note that we do not provide self-access to our units. All the tariffs are to be rented for minimum 4 weeks period.`),
            storageSpace && (React.createElement("h4", { style: storageSpace ? mountedStyle : unmountedStyle }, `Average collection price starting from ${collectionCostOfPlans[storageSpace]}`)),
            React.createElement("div", { className: "storage-space-slide__body" }, isMobile ? (React.createElement("div", { className: "storage-space-slide__carousel-container" },
                React.createElement(Carousel, { selectedIndex: currentSlideIndex > 0 ? currentSlideIndex : 0, items: storageCards }))) : (storageCards))),
        React.createElement(ButtonsBlock, { onNextClick: onNextClick, errorText: error })));
};
export default observer(StorageSpace);
