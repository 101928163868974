import { makeAutoObservable } from 'mobx';
import AppData from './AppData';
class MoverData {
    services = [];
    details = {
        email: '',
        phone: '',
        phonePrefix: '',
        firstName: '',
        lastName: '',
        title: '',
        job_title: '',
        companyName: '',
        comment: '',
    };
    constructor() {
        makeAutoObservable(this);
    }
    setDetails = (details) => {
        this.details = details;
    };
    sendAffiliateData = () => {
        const allData = {
            first_name: this.details.firstName,
            last_name: this.details.lastName,
            phone: `+${this.details.phonePrefix}${this.details.phone}`,
            email: this.details.email,
            title: this.details.title,
            job_title: this.details.job_title,
            company_name: this.details.companyName,
            comment: this.details.comment,
        };
        return AppData.sendAffiliateData(allData);
    };
}
export default new MoverData();
