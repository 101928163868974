import styled from 'styled-components';
export const SlotBlock = styled.div `
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 312px;
`;
export const Container = styled.div `
  display: flex;
  .react-datepicker {
    margin-right: 64px;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    .react-datepicker {
      margin-right: 0;
    }
  }
`;
export const SlotBlockHeader = styled.div `
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;
`;
export const SlotBlockContent = styled.div `
  gap: 12px;
  flex-wrap: wrap;
  align-content: flex-start;
  display: flex;
`;
export const SlotItem = styled.div `
  /* height: min-content; */
  text-align: center;
  width: 150px;
  align-self: baseline;
  border: 1px solid var(--main-grey);
  border-radius: 30px;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 16px;
  cursor: pointer;

  ${({ isChecked }) => isChecked &&
    `
          background-color: #F0512D10;
          border: 1px solid var(--main-color);
    `}
  ${({ disabled }) => disabled &&
    `
          background-color: var(--main-grey);
          color: #B3C2D0;
    `}
`;
