import React, { useEffect, useRef, useState } from 'react';
import { Container, Description, Input, Placeholder, StyledInput } from './Components';
import InputError from './InputError';
const FormInput = ({ onChange, name, value, type = 'text', description, errorText, ref, ariaLabel, bottomMargin = 0, iconUrl, disabled, }) => {
    const [active, setActive] = useState(false);
    const inputRef = useRef(null);
    const slide = () => {
        if (!value) {
            setActive(!active);
        }
    };
    useEffect(() => {
        if (value)
            setActive(true);
    }, [value]);
    const onContainerClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    return (React.createElement(StyledInput, { bottomMargin: bottomMargin },
        React.createElement(Container, { disabled: disabled, active: active, error: !!errorText, onClick: onContainerClick },
            iconUrl && React.createElement("img", { className: "input-icon", src: iconUrl }),
            React.createElement(Placeholder, { style: { marginLeft: iconUrl && !active ? 27 : 0 }, active: active }, name),
            React.createElement(Input, { style: { marginLeft: iconUrl ? 27 : 0 }, isFilled: !!value, "aria-label": ariaLabel, id: "form-input-new", ref: ref || inputRef, onBlur: slide, onFocus: slide, value: value, onChange: e => onChange(e.target.value), type: type, disabled: disabled })),
        !!description && !errorText && React.createElement(Description, null, description),
        !!errorText && React.createElement(InputError, null, errorText)));
};
export default FormInput;
