import React, { useEffect, useRef, useState } from 'react';
import CountrySelectInput from './CountrySelectInput';
import { Container, Description, Input, Placeholder, StyledInput } from './Components';
import InputError from './InputError';
const FormInputPhone = ({ onPrefixChange, onChange, name, value, description, errorText, type = 'text', ref, ariaLabel, }) => {
    const [active, setActive] = useState(false);
    const [phoneFieldOffsetLeft, setPhoneFieldOffsetLeft] = useState(0);
    const inputRef = useRef(null);
    const slide = () => {
        if (!value) {
            setActive(!active);
        }
    };
    const onSetPrefix = (pfx, co) => {
        onPrefixChange(pfx, co);
    };
    const onRefChange = (node) => {
        if (node === null) {
            setPhoneFieldOffsetLeft(phoneFieldOffsetLeft);
        }
        else {
            setPhoneFieldOffsetLeft(node.clientWidth);
        }
    };
    useEffect(() => {
        if (value)
            setActive(true);
    }, [value]);
    const onContainerClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledInput, { bottomMargin: 20 },
            React.createElement(Container, { active: active, error: !!errorText, onClick: onContainerClick },
                React.createElement("div", { style: { display: 'flex', alignItems: 'end' } },
                    React.createElement("div", { onClick: e => e.stopPropagation(), ref: onRefChange },
                        React.createElement(CountrySelectInput, { active: active, onChange: onSetPrefix })),
                    React.createElement("div", { style: { width: '100%' } },
                        React.createElement(Placeholder, { style: { transform: `translateX(-${active ? phoneFieldOffsetLeft : 0}px)` }, active: active }, name),
                        React.createElement(Input, { isFilled: !!value, "aria-label": ariaLabel, id: "form-input-new", ref: ref || inputRef, onBlur: slide, onFocus: slide, value: value, onChange: e => onChange(e.target.value), type: type })))),
            !!description && !errorText && React.createElement(Description, null, description),
            !!errorText && React.createElement(InputError, null, errorText))));
};
export default FormInputPhone;
