import { isMobileSafari } from 'react-device-detect';
import postMessageToWebflow from './postMessageWebflow';
export const declOfNum = (num, text_forms) => {
    const n = Math.abs(num) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) {
        return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
        return text_forms[1];
    }
    if (n1 === 1) {
        return text_forms[0];
    }
    return text_forms[2];
};
export const getCurrentHeight = () => {
    let myHeight = 0;
    if (typeof window.innerWidth === 'number') {
        myHeight = window.innerHeight;
    }
    else if (document.documentElement && document.documentElement.clientHeight) {
        myHeight = document.documentElement.clientHeight;
    }
    else if (document.body && document.body.clientHeight) {
        myHeight = document.body.clientHeight;
    }
    return myHeight;
};
export const scrollToTop = () => {
    setTimeout(() => {
        if (isMobileSafari) {
            postMessageToWebflow('nextClick', 'click');
            window.scrollTo(0, 70);
        }
        else {
            document.querySelector('#root')?.scrollIntoView({ behavior: 'smooth' });
        }
    }, 100);
};
export const getStringDateFromTimeSlot = (slotTime, date) => {
    const jobStartWithUTCOffset = new Date(new Date(date || new Date()).setHours(slotTime, 0, 0, 0));
    jobStartWithUTCOffset.setTime(jobStartWithUTCOffset.getTime() - jobStartWithUTCOffset.getTimezoneOffset() * 60000);
    return jobStartWithUTCOffset.toISOString();
};
export const scrollToError = () => {
    setTimeout(() => {
        const inputError = document.querySelector('#input-error');
        const moduleError = document.querySelector('#module-error');
        if (inputError) {
            if (isMobileSafari) {
                const offset = inputError.getBoundingClientRect().top;
                inputError.scrollIntoView({
                    block: 'center',
                });
                postMessageToWebflow(`${offset}`, 'error-scroll');
            }
            else {
                inputError.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
        else if (moduleError) {
            if (isMobileSafari) {
                const offset = moduleError.getBoundingClientRect().top;
                moduleError.scrollIntoView({
                    block: 'center',
                });
                postMessageToWebflow(`${offset}`, 'error-scroll');
            }
            else {
                moduleError.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    }, 170);
};
