import React from 'react';
import { ItemContainer } from '../../shared/Components';
import { TextContainer } from './Components';
const ServiceItem = ({ description, Icon, title, isChecked, onClick, disabled }) => {
    return (React.createElement(ItemContainer, { "$disabled": disabled, onClick: disabled ? undefined : onClick, isChecked: isChecked },
        React.createElement(TextContainer, { "$disabled": disabled },
            React.createElement("b", null, title),
            React.createElement("span", { style: { width: description.length < 30 ? 110 : 'auto' } }, description)),
        React.createElement(Icon, { black: true, size: 32 })));
};
export default ServiceItem;
