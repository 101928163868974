import React, { useEffect, useState } from 'react';
import { getCountryCallingCode } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import CountrySelect from './CountryCodeSelect';
import en from 'react-phone-number-input/locale/en.json';
import { ArrowDown } from '#Images';
import styled from 'styled-components';
import MainColors from '../DesignColors';
import { isMobile } from 'react-device-detect';
const InputContainer = styled.div `
  display: flex;
  align-items: center;
  transition: 0.25s ease;
`;
const Select = styled.div `
  display: flex;
  align-items: center;
  left: 0;
  cursor: pointer;
  font-size: 16px;
  transition: 0.25s ease;
  color: ${({ active }) => (active ? MainColors.dark : MainColors.secondary)};
  margin-bottom: ${({ active }) => {
    if (!isMobile) {
        return active ? '0px' : '7px';
    }
    else {
        return active ? '7px' : '12px';
    }
}};

  > img {
    padding: 12px;
    width: 34px;
    height: 40px;
    filter: ${({ active }) => (active ? 'none' : 'opacity(0.2)')};

    @media (max-width: 640px) {
      width: 28px;
      height: 28px;
      padding: 8px;
    }
  }

  ${({ opened }) => opened &&
    `
    > img {
        transform: rotate(180deg);
        transition: 0.3s ease;
      }
    `}

  svg {
    width: 23px;
    height: 17px;
    margin-right: 10px;
  }
`;
const CountrySelectInput = ({ onChange, defaultCo = 'GB', active }) => {
    const [prefix, setPrefix] = useState('');
    const [selectedCo, setSelectedCo] = useState(null);
    const [isCoListOpen, setIsCoListOpen] = useState(false);
    const setCountry = (co) => {
        const pfx = getCountryCallingCode(co);
        const flag = flags[co];
        const svgFlag = flag && flag({ title: '' });
        setPrefix(pfx);
        onChange(pfx, co);
        setSelectedCo(svgFlag);
    };
    useEffect(() => {
        setCountry('GB');
    }, []);
    const onCountryCodeClick = () => {
        setIsCoListOpen(!isCoListOpen);
    };
    return (React.createElement(InputContainer, null,
        React.createElement(CountrySelect, { onClose: () => setIsCoListOpen(false), isOpen: isCoListOpen, labels: en, onSelect: (co) => setCountry(co) }),
        React.createElement(Select, { active: active, onClick: onCountryCodeClick, opened: isCoListOpen },
            React.createElement("svg", null, selectedCo),
            `+${prefix || getCountryCallingCode(defaultCo)}`,
            React.createElement("img", { alt: "arrow-icon", src: ArrowDown }))));
};
export default CountrySelectInput;
