import React from 'react';
const MovingIcon = ({ black, size }) => {
    const currentColor = black ? 'black' : 'white';
    const currentSize = size || 40;
    return (React.createElement("svg", { width: currentSize, height: currentSize, viewBox: `0 0 40 40`, fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_1982_34631)" },
            React.createElement("circle", { cx: "19.8704", cy: "32.2488", r: "4", transform: "rotate(-30 19.8704 32.2488)", stroke: currentColor, strokeWidth: "2" }),
            React.createElement("path", { d: "M5.87109 8L17.8711 28.7846", stroke: currentColor, strokeWidth: "2" }),
            React.createElement("path", { d: "M34.5943 23.7488L23.3359 30.2488", stroke: currentColor, strokeWidth: "2" }),
            React.createElement("path", { d: "M15.236 22.2225L23.8962 17.2225C24.3745 16.9463 24.9861 17.1102 25.2622 17.5885L29.7622 25.3827C30.0384 25.861 29.8745 26.4726 29.3962 26.7487L24.2001 29.7487C23.7765 29.9933 23.0571 29.8874 22.2439 29.3058C21.3705 28.6812 20.3542 28.4089 19.2855 28.5131C18.2904 28.6101 17.6145 28.3422 17.3699 27.9186L14.8699 23.5885C14.5938 23.1102 14.7577 22.4986 15.236 22.2225Z", stroke: currentColor, strokeWidth: "2" }),
            React.createElement("path", { d: "M10.736 14.4283L16.7981 10.9283C17.2764 10.6521 17.888 10.816 18.1642 11.2943L21.6642 17.3565C21.9403 17.8348 21.7764 18.4464 21.2981 18.7225L15.236 22.2225C14.7577 22.4987 14.1461 22.3348 13.8699 21.8565L10.3699 15.7943C10.0938 15.316 10.2577 14.7044 10.736 14.4283Z", stroke: currentColor, strokeWidth: "2" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_1982_34631" },
                React.createElement("rect", { width: '40', height: '40', fill: currentColor })))));
};
export default MovingIcon;
