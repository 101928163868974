import React from 'react';
const StorageIcon = ({ black, size }) => {
    const currentColor = black ? 'black' : 'white';
    const currentSize = size || 40;
    return (React.createElement("svg", { width: currentSize, height: "40", viewBox: `0 0 40 40`, fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("mask", { id: "path-1-inside-1_1982_34589", fill: "white" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6 31.0001V15.3189C6 14.5188 6.47679 13.7958 7.21216 13.4806L19.2122 8.33774C19.7153 8.12212 20.2847 8.12212 20.7878 8.33774L32.7878 13.4806C33.5232 13.7957 34 14.5188 34 15.3189V31.0001C34 32.1047 33.1046 33.0001 32 33.0001H8C6.89543 33.0001 6 32.1047 6 31.0001ZM26 21.0001H14V31.0001H26V21.0001Z" })),
        React.createElement("path", { d: "M7.21216 13.4806L6.42432 11.6423L6.42432 11.6423L7.21216 13.4806ZM19.2122 8.33774L20 10.176V10.176L19.2122 8.33774ZM20.7878 8.33774L20 10.176L20.7878 8.33774ZM32.7878 13.4806L33.5757 11.6423H33.5757L32.7878 13.4806ZM14 21.0001V19.0001H12V21.0001H14ZM26 21.0001H28V19.0001H26V21.0001ZM14 31.0001H12V33.0001H14V31.0001ZM26 31.0001V33.0001H28V31.0001H26ZM4 15.3189V31.0001H8V15.3189H4ZM6.42432 11.6423C4.95359 12.2726 4 13.7188 4 15.3189H8L8 15.3189L6.42432 11.6423ZM18.4243 6.49945L6.42432 11.6423L8 15.3189L20 10.176L18.4243 6.49945ZM21.5757 6.49945C20.5695 6.06822 19.4305 6.06822 18.4243 6.49945L20 10.176L20 10.176L21.5757 6.49945ZM33.5757 11.6423L21.5757 6.49945L20 10.176L32 15.3189L33.5757 11.6423ZM36 15.3189C36 13.7188 35.0464 12.2726 33.5757 11.6423L32 15.3189L32 15.3189H36ZM36 31.0001V15.3189H32V31.0001H36ZM32 35.0001C34.2091 35.0001 36 33.2092 36 31.0001H32V35.0001ZM8 35.0001H32V31.0001H8V35.0001ZM4 31.0001C4 33.2092 5.79086 35.0001 8 35.0001V31.0001H4ZM14 23.0001H26V19.0001H14V23.0001ZM16 31.0001V21.0001H12V31.0001H16ZM26 29.0001H14V33.0001H26V29.0001ZM24 21.0001V31.0001H28V21.0001H24Z", fill: currentColor, mask: "url(#path-1-inside-1_1982_34589)" }),
        React.createElement("path", { d: "M2 32H38", stroke: currentColor, strokeWidth: "2" }),
        React.createElement("path", { d: "M10 20H30", stroke: currentColor, strokeWidth: "2" }),
        React.createElement("path", { d: "M14 36H26", stroke: currentColor, strokeWidth: "2" }),
        React.createElement("path", { d: "M13 20V32", stroke: currentColor, strokeWidth: "2" }),
        React.createElement("path", { d: "M27 20V32", stroke: currentColor, strokeWidth: "2" })));
};
export default StorageIcon;
