import useStores from '#Hooks/useStores';
import { dateKeysToTitle } from '#Types/newQuoteTypes';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Checkbox, CheckBoxContainer, Subtitle, Title } from '../../shared/Components';
import DatePicker from '../../shared/DatePicker/DatePicker';
import { Container, SlotBlock, SlotBlockContent, SlotBlockHeader, SlotItem } from './Components';
import dayjs from 'dayjs';
import ModuleError from '../../shared/ModuleError';
import { timeSlots } from '#Constants/dateConst';
const DateModule = ({ kind }) => {
    const { checkoutData: { setDate, dates, setTimeSlot, timeSlot, isStorage, isCleaning, isSameDateChecked, setIsSameDateChecked, }, errorState: { isEmptyDateError, clearErrorByDateKey }, } = useStores();
    useEffect(() => {
        if (isSameDateChecked) {
            setDate(dates['movingDate'], 'storageCollectionDate');
            setDate(dates['movingDate'], 'cleaningDate');
            setTimeSlot(timeSlot['movingDate'], 'storageCollectionDate');
            setTimeSlot(timeSlot['movingDate'], 'cleaningDate');
            clearErrorByDateKey('storageCollectionDate');
            clearErrorByDateKey('cleaningDate');
        }
    }, [isSameDateChecked]);
    const onSelectDate = (d, k) => {
        if (dates[k] === d)
            return;
        setDate(d, k);
        clearErrorByDateKey(k);
        setIsSameDateChecked(false);
    };
    const onSelectTimeSlot = (v, k) => {
        if (timeSlot[k][0] === v[0])
            return;
        setTimeSlot(v, k);
        clearErrorByDateKey(k);
        setIsSameDateChecked(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null, `What is your ${dateKeysToTitle[kind]}`),
        React.createElement(Subtitle, null, 'Please let us know your preferred time slot for arrival of our team. We do our best to accommodate a time slot of arrival, however, it’s dependant on schedule availability and will be confirmed with you.'),
        React.createElement(ModuleError, { opened: isEmptyDateError[kind] }, "Please choose date and time"),
        React.createElement(Container, null,
            React.createElement(DatePicker, { value: dates[kind], onChange: date => onSelectDate(date, kind) }),
            React.createElement(SlotBlock, null,
                React.createElement(SlotBlockHeader, null, `${dates[kind] ? dayjs(dates[kind]).format('dddd, MMMM D') : dayjs().format('dddd, MMMM D')}`),
                React.createElement(SlotBlockContent, null,
                    timeSlots.map((item, key) => (React.createElement(SlotItem, { onClick: () => onSelectTimeSlot(item, kind), key: key, isChecked: item[0] === timeSlot[kind][0] }, `${item[0]}:00 - ${item[1]}:00`))),
                    React.createElement(SlotItem, { onClick: () => onSelectTimeSlot([0, 23], kind), key: 'Anytime', isChecked: 0 === timeSlot[kind][0] }, 'Anytime')))),
        (isStorage || isCleaning) && kind === 'movingDate' && (React.createElement(CheckBoxContainer, null,
            React.createElement(Checkbox, { disabled: !dates.movingDate, type: 'checkbox', checked: isSameDateChecked, onChange: v => setIsSameDateChecked(v.target.checked) }),
            React.createElement("label", null, "Choose this date for all services")))));
};
export default observer(DateModule);
