import './storageTermCard.scss';
import classNames from 'classnames';
import React from 'react';
import InlineSVG from 'react-inlinesvg/esm';
import { CheckMark } from '#Images';
const StorageTermCard = ({ title, isChecked, onClick, term, discount }) => (React.createElement("div", { onClick: onClick, className: classNames('storage-term-card', { 'storage-term-card_checked': isChecked }) },
    isChecked && (React.createElement("div", { className: "storage-term-card__icon" },
        React.createElement(InlineSVG, { src: CheckMark }))),
    React.createElement("div", { className: "storage-term-card__footer" },
        React.createElement("span", { className: "storage-term-card__title" }, title),
        discount !== 0 && (React.createElement("div", { className: "storage-term-card__discount-container" },
            React.createElement("span", { className: "storage-term-card__discount" }, `-${discount}%`))))));
export default StorageTermCard;
