import useStores from '#Hooks/useStores';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Checkbox, CheckBoxContainer, Subtitle, Title } from '../../shared/Components';
import FormInput from '../../shared/FormInput/FormInput';
import FormInputPhone from '../../shared/FormInput/FormInputPhone';
const DetailsModule = () => {
    const { checkoutData: { isStudentPromo, setDetails, details, signForNews, setSignForNews, promo, setPromo, isAgreedWithTerms, setIsAgreed, university, setUniversity, }, errorState: { detailErrors }, } = useStores();
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null, `What are your details?`),
        React.createElement(Subtitle, null, 'Our customer service team will contact you  to finalise the details of your order.'),
        React.createElement(FormInput, { name: 'Email', type: 'email', onChange: email => setDetails({ ...details, email }), value: details.email, description: "We'll use this email to send you your quote breakdown.", bottomMargin: 16, errorText: detailErrors.email }),
        React.createElement(FormInputPhone, { onPrefixChange: phonePrefix => setDetails({ ...details, phonePrefix }), name: 'Phone', type: 'tel', onChange: phone => setDetails({ ...details, phone: phone.replace(/[^0-9]/g, '') }), value: details.phone, description: "We'll use this number to call you and confirm your order details.", bottomMargin: 16, errorText: detailErrors.phone }),
        React.createElement(FormInput, { name: 'First name', onChange: firstName => setDetails({ ...details, firstName }), value: details.firstName, bottomMargin: 16, errorText: detailErrors.firstName }),
        React.createElement(FormInput, { name: 'Last name', onChange: lastName => setDetails({ ...details, lastName }), value: details.lastName, bottomMargin: 16, errorText: detailErrors.lastName }),
        isStudentPromo ? (React.createElement(FormInput, { name: 'University', onChange: uns => setUniversity(uns), value: university, bottomMargin: 16 })) : (React.createElement(FormInput, { name: 'Promo code', onChange: promo => setPromo(promo), value: promo || '', description: `If you don't have any — please leave it blank.`, bottomMargin: 16 })),
        React.createElement("div", { style: { marginTop: 30 } },
            React.createElement(CheckBoxContainer, null,
                React.createElement(Checkbox, { type: 'checkbox', checked: signForNews, onChange: v => setSignForNews(v.target.checked) }),
                React.createElement("label", null, "Sign me up for early promotions and Stackt news")),
            React.createElement(CheckBoxContainer, null,
                React.createElement(Checkbox, { type: 'checkbox', checked: isAgreedWithTerms, onChange: v => setIsAgreed(v.target.checked) }),
                React.createElement("label", null, "I agree with the Terms & Conditions, Privacy Policy and Cookie Preferences")))));
};
export default observer(DetailsModule);
