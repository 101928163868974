import React, { useEffect, useState } from 'react';
import { StyledModuleError } from './Components';
import { scrollToError } from '#Utils/helpers';
const ModuleError = ({ children, opened }) => {
    const [isShown, setIsShown] = useState(false);
    const mountedStyle = { animation: 'inAnimation 250ms ease-in' };
    const unmountedStyle = {
        animation: 'outAnimation 270ms ease-out',
        animationFillMode: 'forwards',
    };
    useEffect(() => {
        if (opened) {
            scrollToError();
            setIsShown(!!opened);
        }
        else {
            setTimeout(() => {
                setIsShown(!!opened);
            }, 270);
        }
    }, [opened]);
    return !isShown ? null : (React.createElement(StyledModuleError, { id: "module-error", style: opened ? mountedStyle : unmountedStyle }, children));
};
export default ModuleError;
