import styled, { css } from 'styled-components';
import MainColors from '../DesignColors';
export const Toast = styled.div `
  transition: all 0.5s ease;
  position: fixed;
  right: 120px;
  top: 106px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  z-index: -1;
  opacity: 0;

  ${props => props.show &&
    css `
      opacity: 1;
      z-index: 100;
    `}

  background: ${props => (props.type === 'success' ? MainColors.dark : MainColors.primary)};
  border-radius: 8px;
`;
export const CalendarToast = styled(Toast) `
  position: fixed;
  right: 20px;
  bottom: 40px;
  top: auto;
`;
export const IconContainer = styled.div `
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Icon = styled.img `
  filter: invert(1);
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  height: ${props => (props.height ? props.height + 'px' : 'auto')};
`;
