import styled from 'styled-components';
import React from 'react';
import { CheckMarkWhite, ExclamationMark } from '#Images';
import { observer } from 'mobx-react-lite';
import useStores from '#Hooks/useStores';
import { scrollToError, scrollToTop } from '#Utils/helpers';
import { NewEvents, trackNewEvent } from '#Utils/amplitudeAnalyticsService';
const Container = styled.div `
  max-width: 226px;
  display: flex;
  flex-direction: column;

  @media (max-width: 640px) {
    max-width: initial;
    flex-direction: row;
    margin-top: 16px;
    justify-content: space-between;
  }
`;
const ItemContainer = styled.div `
  display: flex;
  align-items: center;
  :not(:last-child) {
    margin-bottom: 24px;
  }
  cursor: pointer;

  @media (max-width: 640px) {
    flex-direction: column;
    margin-bottom: 0px;
    :not(:last-child) {
      margin-right: 40px;
    }
  }
`;
const Circle = styled.div `
  width: 46px;
  height: 46px;
  border-radius: 50%;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  background-color: #b3c2d0;
  color: #fff;
  border: none;

  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  @media (max-width: 640px) {
    width: 38px;
    height: 38px;
    font-size: 16px;
    line-height: 21px;
    margin-right: 0;
    margin-bottom: 8px;
  }

  ${({ active }) => active &&
    `
          background-color: #fff;
          color: var(--main-black);
          border: 2px solid var(--main-black);
    `}
  ${({ disabled }) => disabled &&
    `
          background-color: var(--main-grey);
          color: #B3C2D0;
    `}
    ${({ completed }) => completed &&
    `
        background-image: url(${CheckMarkWhite});
        background-repeat: no-repeat;
        background-position: center;


    `}
    ${({ error }) => error &&
    `
        background-image: url(${ExclamationMark});
        background-repeat: no-repeat;
        background-position: center;
        background-color: var(--main-color);
        color: none;
    `}
`;
const Title = styled.div `
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  flex-wrap: wrap;

  ${({ disabled }) => disabled &&
    `
         color: var(--main-grey);
    `}
  ${({ error }) => error &&
    `
        color: var(--main-color);
    `}

@media (max-width: 640px) {
    font-size: 12px;
    line-height: 16px;
    /* width: min-content; */
    max-width: 60px;
    text-align: center;
  }
`;
const StepsBlock = () => {
    const { checkoutData: { currentSlide, isPlansNeeded, isStorageAndReturn, setCurrentSlide, services, isAddressFilled, isDateFilled, isTimeSlotsFilled, isPlansFilled, isOnlyPackaging, isStudentMailFilled, isServiceChanged, setIsMovedFromService, }, errorState: { setEmptyServiceError, setOneMoreServiceError, setEmptyDateErrors, setEmptyAddressErrors, setEmptyPlansErrors, setSchoolEmailError, }, } = useStores();
    const onSetNextSlide = (key) => {
        trackNewEvent(NewEvents.checkoutv2_any_step_clicked);
        if (isStorageAndReturn)
            return;
        if (currentSlide === 'service' && !services.length)
            return setEmptyServiceError(true);
        if (currentSlide === 'service' && isOnlyPackaging)
            return setOneMoreServiceError();
        if (currentSlide === 'service' && key === 'dateAndTime' && !isPlansFilled && isPlansNeeded)
            return;
        if (currentSlide === 'service' && key === 'contactDetails' && (!isDateFilled || !isAddressFilled))
            return;
        if (currentSlide === 'plans' && !isPlansFilled && key !== 'service')
            return setEmptyPlansErrors();
        if (currentSlide === 'plans' && !isDateFilled && key === 'contactDetails')
            return;
        if (currentSlide === 'dateAndTime' && (!isDateFilled || !isAddressFilled) && key === 'contactDetails') {
            setEmptyDateErrors();
            setEmptyAddressErrors();
            return;
        }
        if ((currentSlide === 'plans' || currentSlide === 'dateAndTime') && !isStudentMailFilled)
            return setSchoolEmailError(), scrollToError();
        if (currentSlide !== 'service') {
            setIsMovedFromService(true);
        }
        scrollToTop();
        setCurrentSlide(key);
    };
    const items = [
        {
            active: currentSlide === 'service',
            completed: !!services.length && currentSlide !== 'service',
            disabled: false,
            title: 'Services',
            onClick: () => onSetNextSlide('service'),
            errorState: false,
        },
        {
            active: currentSlide === 'plans',
            completed: isPlansFilled && currentSlide !== 'plans' && isPlansNeeded,
            disabled: !isPlansNeeded,
            title: 'Plans',
            onClick: () => onSetNextSlide('plans'),
            errorState: isServiceChanged && !isPlansFilled && currentSlide !== 'plans',
        },
        {
            active: currentSlide === 'dateAndTime',
            completed: !!services.length && isAddressFilled && isDateFilled && isTimeSlotsFilled && currentSlide !== 'dateAndTime',
            disabled: false,
            title: 'Date',
            onClick: () => onSetNextSlide('dateAndTime'),
            errorState: isServiceChanged && !isAddressFilled && !isDateFilled && !isTimeSlotsFilled && currentSlide !== 'dateAndTime',
        },
        {
            active: currentSlide === 'contactDetails',
            completed: false,
            disabled: false,
            title: 'Details',
            onClick: () => onSetNextSlide('contactDetails'),
            errorState: false,
        },
    ];
    return (React.createElement(Container, null, items.map((item, index) => (React.createElement(ItemContainer, { onClick: () => {
            !item.disabled && item.onClick();
        }, key: index },
        React.createElement(Circle, { error: item.errorState, disabled: item.disabled, active: item.active, completed: item.completed }, item.completed || item.errorState ? '' : index + 1),
        React.createElement(Title, { error: item.errorState, disabled: item.disabled, active: item.active, completed: item.completed }, item.title))))));
};
export default observer(StepsBlock);
