import MainColors, { scrollbarCss } from '#Pages/NewCheckout/components/shared/DesignColors';
import styled from 'styled-components';
export const Container = styled.div `
  position: relative;
  width: 100%;
`;
export const SuggestionsContainer = styled.div `
  width: 100%;
  padding: 16px 18px;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: absolute;
  top: 66px;
  max-height: 352px;
  margin-top: 4px;
  border: 1px solid ${MainColors.dark};
  border-radius: 15px;
  box-shadow: 0 0 0 1px hsl(0, 0%, 80%);
  color: ${MainColors.dark};
  width: calc(100% - 2px);
  z-index: 1;

  font-size: 16px;
  line-height: 22px;
`;
export const SuggestionsContentContainer = styled.div `
  overflow-x: auto;
  ${scrollbarCss}
`;
export const SuggestionRow = styled.div `
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;

  :not(:last-child) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 640px) {
    padding: 0;
    :not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &:hover {
    background-color: #e6ebef30;
  }
`;
