import './cleaningPriceCard.scss';
import classnames from 'classnames';
import React from 'react';
import InlineSVG from 'react-inlinesvg/esm';
import { CheckMark, ClockIcon, ManIcon } from '#Images';
import { declOfNum } from '#Utils/helpers';
const CleaningPrice = ({ title, isChecked, onClick, price, cleaners, hours, extraFee, description, iconUrl, }) => (React.createElement("div", { onClick: onClick, className: `cleaning-card ${isChecked ? 'cleaning-card_checked' : ''}` },
    isChecked && (React.createElement("div", { className: "cleaning-card__icon" },
        React.createElement(InlineSVG, { src: CheckMark }))),
    React.createElement(InlineSVG, { style: { display: 'block', margin: '0 auto' }, src: iconUrl }),
    React.createElement("span", { className: classnames('cleaning-card__title') }, title),
    description ? (React.createElement("span", { className: classnames('cleaning-card__size') }, description)) : (React.createElement("span", { className: classnames('cleaning-card__size') },
        React.createElement("div", { className: "cleaning-card__size-item" },
            React.createElement("img", { className: "cleaning-card__description-icon", alt: "icon", src: ManIcon }),
            `${cleaners} ${declOfNum(cleaners, ['CLEANER', 'CLEANERS', 'CLEANERS'])}`),
        React.createElement("div", { className: "cleaning-card__size-item" },
            React.createElement("img", { className: "cleaning-card__description-icon", alt: "icon", src: ClockIcon }),
            `${hours} ${declOfNum(hours, ['HOUR', 'HOURS', 'HOURS'])}`))),
    !!price && React.createElement("span", { className: "cleaning-card__price" }, price),
    !!extraFee && React.createElement("span", { className: classnames('cleaning-card__period') },
        "extra hourly fee -- \u00A3",
        extraFee)));
export default CleaningPrice;
