import useStores from '#Hooks/useStores';
import { postCodeKeysToFieldName, postCodeKeysToTitle } from '#Types/newQuoteTypes';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Checkbox, CheckBoxContainer, Subtitle, Title } from '../../shared/Components';
import GetAddress from './GetAddressNew';
const AddressModule = ({ kind }) => {
    const { checkoutData: { addresses, setAddress, isStorage, isCleaning, isSameAddressesChecked, setIsSameAddressesChecked }, errorState: { isEmptyAddressError, setEmptyAddressErrors, clearErrorByAddressKey }, } = useStores();
    useEffect(() => {
        if (isSameAddressesChecked) {
            setAddress(addresses['collection'], 'storage_collection');
            setAddress(addresses['collection'], 'cleaning');
            clearErrorByAddressKey('storage_collection');
            clearErrorByAddressKey('cleaning');
        }
    }, [isSameAddressesChecked]);
    const isFieldDisabled = () => isSameAddressesChecked && (kind === 'cleaning' || kind === 'storage_collection');
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null, `What's your ${postCodeKeysToTitle[kind]} postcode in the UK?`),
        React.createElement(Subtitle, null, 'Keep typing to display more results.'),
        React.createElement(GetAddress, { isDisabled: isFieldDisabled(), errorText: isEmptyAddressError[kind] ? 'Please enter your postcode' : '', defaultValue: addresses[kind].address, onChange: addr => {
                setAddress(addr, kind);
                clearErrorByAddressKey(kind);
            }, title: postCodeKeysToFieldName[kind], description: "We need your postcode to find your address" }),
        (isStorage || isCleaning) && kind === 'collection' && (React.createElement(CheckBoxContainer, null,
            React.createElement(Checkbox, { disabled: !addresses.collection.address, type: 'checkbox', checked: isSameAddressesChecked, onChange: v => setIsSameAddressesChecked(v.target.checked) }),
            React.createElement("label", null, "Choose this postcode for all services")))));
};
export default observer(AddressModule);
