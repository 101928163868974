import axios from 'axios';
import * as Sentry from '@sentry/browser';
const apiService = axios.create({
    baseURL: '',
});
export default class ApiService {
    postResource = async (url, data, params) => apiService.post(`${url}`, data, {
        params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
        },
    });
    getResource = async (url, params) => apiService.get(`${url}`, {
        params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
        },
    });
    saveData = async (data) => {
        try {
            const res = await this.postResource(`/api/v1/easy_checkout`, data);
            return res;
        }
        catch (error) {
            console.log(error);
            Sentry.captureException(error);
            throw error;
        }
    };
    saveStudentData = async (data) => {
        try {
            const res = await this.postResource(`/api/v1/students_quote`, data);
            return res;
        }
        catch (error) {
            console.log(error);
            Sentry.captureException(error);
            throw error;
        }
    };
    saveMoversData = async (data) => {
        try {
            const res = await this.postResource(`/api/v1/movers_quote`, data);
            return res;
        }
        catch (error) {
            console.log(error);
            Sentry.captureException(error);
            throw error;
        }
    };
    saveAffiliateData = async (data) => {
        try {
            const res = await this.postResource(`/api/v1/partners_quote`, data);
            return res;
        }
        catch (error) {
            console.log(error);
            Sentry.captureException(error);
            throw error;
        }
    };
}
