import { makeAutoObservable } from 'mobx';
import AppData from './AppData';
class MoverData {
    services = [];
    details = {
        email: '',
        phone: '',
        phonePrefix: '',
        firstName: '',
        lastName: '',
        hasTrolley: false,
        vehicleNumber: '',
    };
    constructor() {
        makeAutoObservable(this);
    }
    setDetails = (details) => {
        this.details = details;
    };
    sendMoverData = () => {
        const allData = {
            first_name: this.details.firstName,
            last_name: this.details.lastName,
            phone: `+${this.details.phonePrefix}${this.details.phone}`,
            email: this.details.email,
            vehicle_registration_number: this.details.vehicleNumber,
            trolley: this.details.hasTrolley,
        };
        return AppData.sendMoversData(allData);
    };
}
export default new MoverData();
