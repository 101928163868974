import 'react-phone-number-input/style.css';
import React, { useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import InfiniteScroll from 'react-infinite-scroll-component';
import flags from 'react-phone-number-input/flags';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import { SearchIcon } from '#Images/index';
import styled from 'styled-components';
import MainColors, { scrollbarCss } from '../DesignColors';
const SelectListWrapper = styled.div `
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 70px;
  left: 0;
  border: 1px solid #1b1b1e;
  border-radius: 15px;

  z-index: 3;
  overflow: hidden;
  width: 100%;
  max-height: 384px;
  background-color: #fff;
  padding: 16px 18px;

  @media (max-width: 640px) {
    max-height: 325px;
    padding: 6px 18px 18px 18px;
  }
`;
const SearchWrapper = styled.div `
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${MainColors.secondary};
  padding: 12px;
  margin-bottom: 4px;

  img {
    width: 16px;
    height: 16px;
    margin-right: 20px;

    @media (max-width: 640px) {
      margin-right: 10px;
    }
  }

  input {
    border: none;
    font-family: 'TT Travels', serif;
    transition: all 0.5s;
    font-size: 16px;
    line-height: 22px;
    background: none;
    width: 85%;
    max-width: 720px;
    font-weight: 400;
  }
`;
const List = styled.div `
  overflow-x: auto;

  ul {
    list-style-type: none;
    padding: 0;
  }

  ${scrollbarCss}
`;
const Item = styled.div `
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 0;
  border-radius: 8px;

  cursor: pointer;

  &:hover {
    background-color: #e6ebef30;
  }

  &-flag {
    width: 25px;
    height: 25px;
    margin-right: 16px;

    @media (max-width: 640px) {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
`;
const Flag = styled.svg `
  width: 25px;
  height: 25px;
  margin-right: 16px;

  @media (max-width: 640px) {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;
const CountrySelect = ({ onClose, isOpen, onSelect, labels }) => {
    const [search, setSearch] = useState('');
    const wrapperRef = useOnclickOutside(e => {
        onClose(e);
    });
    const onItemClick = (co) => {
        onSelect(co);
        onClose();
    };
    const filterData = (arr) => arr.filter(co => labels[co].toLocaleLowerCase().startsWith(search.toLocaleLowerCase()));
    const [preloadedItems, setPreloadedItems] = useState(getCountries().slice(0, 7));
    const fetchMoreData = () => {
        setPreloadedItems(prev => Array.from(new Set([...filterData(prev), ...filterData(getCountries()).slice(prev.length + 1, prev.length + 5)])));
    };
    useEffect(() => {
        setPreloadedItems(prev => Array.from(new Set([...filterData(prev), ...filterData(getCountries()).slice(0, 5)])));
    }, [search]);
    return (React.createElement(React.Fragment, null, isOpen && (React.createElement(SelectListWrapper, { ref: wrapperRef },
        React.createElement(SearchWrapper, null,
            React.createElement("img", { alt: "search-icon", src: SearchIcon }),
            React.createElement("input", { value: search, onChange: e => setSearch(e.target.value), placeholder: "Search..." })),
        React.createElement(List, { id: "Scroller" },
            React.createElement(InfiniteScroll, { scrollableTarget: "Scroller", dataLength: preloadedItems.length, next: fetchMoreData, hasMore: true, loader: React.createElement(React.Fragment, null) }, preloadedItems.map(country => {
                const flag = flags[country];
                const svgFlag = flag && flag({ title: '' });
                return (React.createElement(Item, { onClick: () => onItemClick(country), key: country },
                    React.createElement(Flag, null, svgFlag),
                    `${labels[country]} (+${getCountryCallingCode(country)})`));
            })))))));
};
export default CountrySelect;
