import './dropdown.scss';
import React, { useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { Input, InputMainWrapper } from '#Components';
const Dropdown = ({ selected, items, placeholder, onChange }) => {
    const [filter, setFilter] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const ref = useOnclickOutside(() => {
        setSuggestions([]);
    });
    const onSelect = (item) => {
        onChange(item);
        setFilter(item);
        setSuggestions([]);
    };
    useEffect(() => {
        if (selected !== filter) {
            setSuggestions(items.filter(item => item.value.toLowerCase().startsWith(filter.toLowerCase())));
        }
    }, [filter]);
    return (React.createElement("div", { ref: ref, className: "dropdown" },
        React.createElement(InputMainWrapper, { title: "Start entering the country" },
            React.createElement(Input, { value: filter, placeholder: placeholder, onChange: val => setFilter(val) })),
        !!suggestions.length && (React.createElement("div", { className: "dropdown__suggestions" }, suggestions.map((item) => (React.createElement("div", { key: item.key, onClick: () => onSelect(item.value), className: "dropdown__suggestion-item" }, item.value)))))));
};
export default Dropdown;
