import { declOfNum } from '#Utils/helpers';
import React from 'react';
// import { ColumnItemContainer } from '../../shared/Components';
import { ExtraFee, FooterRow, HeaderRow } from './Components';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { Colors, MainColors } from '../../shared/DesignColors';
const ItemContainer = styled.div `
  max-width: 232px;
  min-height: 66px;
  width: 100%;
  display: flex;
  padding: 12px;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${({ isChecked }) => (isChecked ? MainColors[Colors.primary] : MainColors[Colors.secondary])};
  background-color: ${({ isChecked }) => (isChecked ? 'rgba(240, 81, 45, 0.063)' : 'white')};
  border-radius: 15px;

  @media (max-width: 640px) {
    min-height: 106px;
    max-width: 158px;
    font-size: 12px;
    line-height: 16px;
  }
`;
const ColumnItemContainer = styled(ItemContainer) `
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;
const PropertyTypeItem = ({ isChecked, onClick, price, title, cleaners, extraFee, hours }) => {
    return isMobile ? (React.createElement(ColumnItemContainer, { onClick: onClick, isChecked: isChecked },
        React.createElement(HeaderRow, null,
            React.createElement("b", null, title),
            React.createElement("span", null, `${hours} h · ${cleaners} ${declOfNum(cleaners, ['Cleaner', 'Cleaners', 'Cleaners'])} `)),
        React.createElement("span", null,
            React.createElement("b", null, price),
            React.createElement(ExtraFee, null, ` +£${extraFee}*`)))) : (React.createElement(ColumnItemContainer, { onClick: onClick, isChecked: isChecked },
        React.createElement(HeaderRow, null,
            React.createElement("span", null, title),
            React.createElement("span", null, price)),
        React.createElement(FooterRow, null,
            React.createElement("span", null, `${hours} h · ${cleaners} ${declOfNum(cleaners, ['Cleaner', 'Cleaners', 'Cleaners'])} `),
            React.createElement("span", null, `£${extraFee}*`))));
};
export default PropertyTypeItem;
