import Client from 'getaddress-api';
import React, { useEffect, useRef, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import useDebounce from '#Hooks/useDebounce';
import { TEST_KEY } from './apiKeys';
import { Container, SuggestionRow, SuggestionsContainer, SuggestionsContentContainer } from '../../../shared/Dropdown';
import FormInput from '#Pages/NewCheckout/components/shared/FormInput/FormInput';
import mapIcon from '#Images/map-small.svg';
const api = new Client(TEST_KEY);
const GetAddress = ({ defaultValue, onChange, description, title, isDisabled, errorText }) => {
    const [suggestions, setSuggestions] = useState([]);
    const [debouncedInputVal, inputValue, setInputValue] = useDebounce('', 500);
    useEffect(() => {
        setInputValue(defaultValue);
    }, [defaultValue]);
    const ref = useOnclickOutside(() => {
        setSuggestions([]);
    });
    const isSet = useRef(false);
    const asyncSearch = async (val) => {
        const autocompleteResult = await api.autocomplete(val);
        if (autocompleteResult.isSuccess) {
            const success = autocompleteResult.toSuccess();
            setSuggestions(success.suggestions);
        }
        else {
            const failed = autocompleteResult.toFailed();
            console.log(failed);
        }
    };
    const handleInput = (v) => {
        setInputValue(v);
        isSet.current = false;
    };
    useEffect(() => {
        if (defaultValue !== debouncedInputVal && !isSet.current) {
            asyncSearch(debouncedInputVal);
        }
    }, [debouncedInputVal]);
    const handleSelect = (addr, id) => async () => {
        isSet.current = true;
        setInputValue(addr);
        setSuggestions([]);
        const addressResult = await api.get(id);
        if (addressResult.isSuccess) {
            const res = addressResult.toSuccess();
            console.log('Selected Address', res.address);
            onChange({ address: addr, postcode: res.address.postcode });
        }
        else {
            const failed = addressResult.toFailed();
            onChange({ address: addr, postcode: '' });
            console.log(failed);
        }
    };
    const renderSuggestions = () => suggestions.map(suggestion => {
        const { address, id } = suggestion;
        return (React.createElement(SuggestionRow, { key: id, onClick: handleSelect(address, id) }, address));
    });
    return (React.createElement(Container, { ref: ref },
        React.createElement(FormInput, { errorText: errorText, name: title || '', onChange: handleInput, value: inputValue, description: description, iconUrl: mapIcon, disabled: isDisabled }),
        !!suggestions.length && (React.createElement(SuggestionsContainer, null,
            React.createElement(SuggestionsContentContainer, null, renderSuggestions())))));
};
export default GetAddress;
