import React from 'react';
const ShippingIcon = ({ black, size }) => {
    const currentColor = black ? 'black' : 'white';
    const currentSize = size || 40;
    return (React.createElement("svg", { width: currentSize, height: currentSize, viewBox: `0 0 40 40`, fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M17.4714 28.5286L33.4142 12.5858C33.7893 12.2107 34 11.702 34 11.1716V8C34 6.89543 33.1046 6 32 6L28.8284 6C28.298 6 27.7893 6.21072 27.4142 6.58579L11.4714 22.5286C11.1654 22.8346 10.7684 23.0331 10.34 23.0943L4.85858 23.8773C4.36593 23.9477 4 24.3696 4 24.8673V27.1529C4 27.6417 4.35341 28.0589 4.8356 28.1393L9.6839 28.9473C9.88948 28.9816 10.0792 29.0792 10.2266 29.2266L10.7734 29.7734C10.9208 29.9208 11.0184 30.1105 11.0527 30.3161L11.8607 35.1644C11.9411 35.6466 12.3583 36 12.8471 36H15.1327C15.6304 36 16.0523 35.6341 16.1227 35.1414L16.9057 29.66C16.9669 29.2316 17.1654 28.8346 17.4714 28.5286Z", stroke: currentColor, strokeWidth: "2" }),
        React.createElement("path", { d: "M29 36V17L23 23L24.8773 36.1414C24.9477 36.6341 25.3696 37 25.8673 37H28C28.5523 37 29 36.5523 29 36Z", stroke: currentColor, strokeWidth: "2" }),
        React.createElement("path", { d: "M4 11H23L17 17L3.85858 15.1227C3.36593 15.0523 3 14.6304 3 14.1327V12C3 11.4477 3.44772 11 4 11Z", stroke: currentColor, strokeWidth: "2" })));
};
export default ShippingIcon;
