import styled from 'styled-components';
import MainColors from '../../shared/DesignColors';
export const HeaderRow = styled.div `
  display: flex;
  justify-content: space-between;
  font-weight: 600;

  @media (max-width: 640px) {
    flex-direction: column;
    font-weight: 400;
  }
`;
export const ExtraFee = styled.span `
  color: ${MainColors.dark};
`;
export const FooterRow = styled(HeaderRow) `
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;
export const FootNote = styled.div `
  margin-top: 16px;
  margin-bottom: 48px;
  color: ${MainColors.secondary};
`;
