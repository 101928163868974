import './socialsBlock.scss';
import React from 'react';
import { FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon } from '#Images';
const SocialsBlock = () => (React.createElement("div", { className: "socials-block" },
    React.createElement("a", { href: "https://www.linkedin.com/company/stacktcouk", target: "_top" },
        React.createElement("img", { src: LinkedInIcon, alt: "LinkedInIcon" })),
    React.createElement("a", { href: "https://www.facebook.com/stackt.co.uk/", target: "_top" },
        React.createElement("img", { src: FacebookIcon, alt: "FacebookIcon" })),
    React.createElement("a", { href: "https://twitter.com/stacktlive", target: "_top" },
        React.createElement("img", { src: TwitterIcon, alt: "TwitterIcon" })),
    React.createElement("a", { href: "https://www.instagram.com/Stackt.co.uk/", target: "_top" },
        React.createElement("img", { src: InstagramIcon, alt: "InstagramIcon" }))));
export default SocialsBlock;
