import { css } from 'styled-components';
export var Colors;
(function (Colors) {
    Colors["primary"] = "primary";
    Colors["secondary"] = "secondary";
    Colors["dark"] = "dark";
    Colors["darkGrey"] = "darkGrey";
})(Colors || (Colors = {}));
export const scrollbarCss = css `
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e6ebef;
    border-radius: 4px;
  }

  @media (max-width: 640px) {
    ::-webkit-scrollbar {
      width: 2px;
    }
  }
`;
export const MainColors = {
    [Colors.primary]: '#f0512d',
    [Colors.secondary]: '#cdd7e0',
    [Colors.dark]: '#1b1b1e',
    [Colors.darkGrey]: '#998f8f',
};
export default MainColors;
