import useStores from '#Hooks/useStores';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Subtitle, Title } from '../../shared/Components';
import RadioRow from '../../shared/RadioRow';
import FormInput from '../../shared/FormInput/FormInput';
// import { mailRegex } from '#Utils/validators';
const AreYouStudent = () => {
    const { checkoutData: { isStudent, setIsStudent, schoolEmail, setSchoolEmail }, errorState: { isStudentEmailError, setSchoolEmailError }, } = useStores();
    const setEmail = (v) => {
        setSchoolEmail(v);
        setSchoolEmailError();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null, `Are you a student?`),
        React.createElement(Subtitle, null, 'Leave your student email before placing an order and get 10% off a monthly plan. Please note that it cannot be used in conjunction with other discounts, subject to certain exemptions.'),
        React.createElement(RadioRow, { onChange: setIsStudent, options: [
                { value: 'true', label: 'Yes, I am' },
                { value: 'false', label: 'No, I am not' },
            ], value: isStudent, radioName: 'student' }),
        isStudent.value === 'true' && (React.createElement("div", { style: { marginTop: 18 } },
            React.createElement(FormInput, { name: 'Student email (.edu, .ac.uk)', type: 'email', onChange: email => setEmail(email), value: schoolEmail, 
                // description="We'll use this email to send you your quote breakdown."
                bottomMargin: 16, errorText: isStudentEmailError ? 'Please type correct email' : '' })))));
};
export default observer(AreYouStudent);
