import styled from 'styled-components';
import MainColors, { Colors } from '../../shared/DesignColors';
export const CardHeader = styled.div `
  height: 232px;
  background-color: ${({ isChecked }) => (isChecked ? 'transparent' : '#e6ebef')};
  border-radius: 24px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  padding: 12px;
`;
export const CardFooter = styled.div `
  padding: 8px;
`;
export const CrossedPrice = styled.del `
  color: ${MainColors[Colors.secondary]};
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;
export const FlexRow = styled.div `
  display: flex;
  justify-content: space-between;
`;
export const ItemContainer = styled.div `
  width: 232px;
  height: 333px;
  /* width: 100%; */
  display: flex;
  /* padding: 12px; */
  align-items: center;
  cursor: pointer;
  border: 1px solid #cdd7e0;
  border-radius: 24px;

  img {
    margin: 0 auto;
  }

  ${({ isChecked }) => isChecked &&
    `
          background-color: #F0512D10;
          border: 1px solid var(--main-color);
    `}
`;
export const BoldFont = styled.span `
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;
export const Price = styled(BoldFont) `
  color: ${MainColors[Colors.primary]};
`;
export const SelectBtn = styled.span `
  color: ${({ selected }) => (selected ? MainColors[Colors.primary] : MainColors[Colors.dark])};
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  &::before {
    content: '✚';
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }

  ${({ selected }) => selected &&
    `
          ::before {
            content: '✔';
          }
    `}
`;
export const ColumnItemContainer = styled(ItemContainer) `
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  @media screen and (max-width: 640px) {
    margin-right: 16px;
  }
`;
