import './quoteWrapper.scss';
import React, { useEffect, useState } from 'react';
import ProgressBar from '#Components/ProgressBar/ProgressBar';
import usePrevious from '#Hooks/usePrevious';
const QuoteWrapper = ({ quoteTitle, children, slideTitle, progress, currentSlide }) => {
    const [animationClass, setaAnimationClass] = useState('');
    const prev = usePrevious(currentSlide);
    useEffect(() => {
        if (currentSlide > (prev ?? 0)) {
            setaAnimationClass('move-slide-right');
        }
        if (currentSlide < (prev ?? 0)) {
            setaAnimationClass('move-slide-left');
        }
        setTimeout(() => {
            setaAnimationClass('');
        }, 500);
    }, [currentSlide]);
    return (React.createElement("div", { className: "quote-wrapper" },
        React.createElement("h1", { className: "quote-wrapper__name" }, quoteTitle),
        React.createElement(ProgressBar, { progress: progress }),
        React.createElement("div", { className: animationClass },
            React.createElement("h3", { className: "quote-wrapper__title" }, slideTitle),
            children)));
};
export default QuoteWrapper;
