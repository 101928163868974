import './postcode.scss';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import DropdownGetAddress from '#Components/Dropdown/DropdownGetAddress';
import { ButtonsBlock } from '#Components/index';
import useEnterClick from '#Hooks/useEnterClick';
import useStores from '#Hooks/useStores';
import { getAnalyticSKeys, getPCEnteredType, getPCErrorType, getPCOpenType, trackEvent, } from '#Utils/amplitudeAnalyticsService';
import postMessageToWebflow from '#Utils/postMessageWebflow';
const Postcode = ({ isRequired, kind }) => {
    const [error, setError] = useState('');
    const { quoteData: { addresses, setAddress, setNextSlide, services }, } = useStores();
    const onSetAddress = (addr) => {
        setError('');
        setAddress(addr, kind);
        // amplitude-analytics
        const postcodeKey = getPCEnteredType(kind);
        trackEvent(postcodeKey, { postcode_first_part: addr.postcode });
    };
    const onNextClick = () => {
        if (isRequired && addresses[kind].postcode) {
            setNextSlide();
            postMessageToWebflow('nextClick', 'click');
            return;
        }
        setError(`Please fill out all required fields`);
        setTimeout(() => {
            setError('');
        }, 4000);
        // amplitude-analytics
        const postcodeKey = getPCErrorType(kind);
        trackEvent(postcodeKey);
    };
    useEnterClick(onNextClick);
    useEffect(() => {
        // amplitude-analytics
        const postcodeKey = getPCOpenType(kind);
        if (kind === 'collection') {
            trackEvent(postcodeKey, { items: getAnalyticSKeys(services) });
        }
        else {
            trackEvent(postcodeKey);
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("h4", { style: { marginBottom: 40 } }, "Keep typing your address to display more results."),
        React.createElement(DropdownGetAddress, { value: addresses[kind].address, error: error, onChange: onSetAddress }),
        React.createElement(ButtonsBlock, { onNextClick: onNextClick })));
};
export default observer(Postcode);
