import './checkout.scss';
import { init } from '@amplitude/analytics-browser';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { QuoteWrapper } from '#Components';
import { CleaningPrice, Country, Credentials, DateSlide, Final, PickServices, Postcode, StorageSpace, StorageTerm, } from '#Components/slides';
import { API_KEY } from '#Constants/amplitudeAnalytics';
import useStores from '#Hooks/useStores';
import { ServiceKeys } from '#Types/quoteTypes';
import { Events, trackEvent } from '#Utils/amplitudeAnalyticsService';
import { parseCleaningPlan, parseClientIdFromPm, parseClientIdFromSearchString, parseStoragePlan, } from '#Utils/queryStrParsers';
import parseUtmSearchStr from '#Utils/parseUtmSearchStr';
import useIframePm from '#Hooks/useIframePm';
init(API_KEY);
const CheckOut = () => {
    const { quoteData: { currentSlide, isShipping, isStorage, isMoving, isCleaning, isOnlyCleaning, setTotalSlides, services, setUtmParams, setGtmClientId, setStorageSpace, setCleaningSpace, setService, clientId, }, } = useStores();
    const slidesToRender = {
        service: {
            title: 'How can we help you?',
            component: React.createElement(PickServices, { key: "service", isRequired: true }),
        },
        ...(!isOnlyCleaning && {
            collectionPost: {
                title: "What's your collection postcode?",
                component: React.createElement(Postcode, { key: "collectionPost", isRequired: true, kind: "collection" }),
            },
        }),
        ...(isCleaning && {
            cleaningPost: {
                title: "What's your cleaning location postcode?",
                component: React.createElement(Postcode, { key: "cleaningPost", isRequired: true, kind: "cleaning" }),
            },
        }),
        ...(isMoving && {
            deliveryPost: {
                title: "What's your delivery postcode?",
                component: React.createElement(Postcode, { key: "deliveryPost", isRequired: true, kind: "delivery" }),
            },
        }),
        ...(isShipping && {
            deliveryCountry: {
                title: "What's your delivery country?",
                component: React.createElement(Country, { key: "deliveryCountry", isRequired: true }),
            },
        }),
        ...(isStorage && {
            storageSpace: {
                title: 'How much space do you need?',
                component: React.createElement(StorageSpace, { key: "storageSpace", isRequired: true }),
            },
        }),
        ...(isStorage && {
            storageTerm: {
                title: 'For how long do you want to store?',
                component: React.createElement(StorageTerm, { key: "storageTerm", isRequired: true }),
            },
        }),
        ...(isCleaning && {
            cleaningPrice: {
                title: 'Tell us your property type:',
                component: React.createElement(CleaningPrice, { key: "cleaningPrice", isRequired: true }),
            },
        }),
        ...(!isOnlyCleaning && {
            movingDate: {
                title: 'What is your moving date?',
                component: React.createElement(DateSlide, { key: "movingDate", isRequired: true, kind: "movingDate" }),
            },
        }),
        ...(isCleaning && {
            cleaningDate: {
                title: 'What is your cleaning date?',
                component: React.createElement(DateSlide, { key: "cleaningDate", isRequired: true, kind: "cleaningDate" }),
            },
        }),
        credentials: {
            title: "Great! What's your details?",
            component: React.createElement(Credentials, { key: "credentials", isRequired: true }),
        },
        final: {
            title: 'Stay tuned!👋',
            component: React.createElement(Final, { key: "final" }),
        },
    };
    const slidesMap = Object.entries(slidesToRender);
    const totalSlides = slidesMap.length;
    useEffect(() => {
        setTotalSlides(totalSlides);
    }, [toJS(services)]);
    useEffect(() => {
        const utmMarks = parseUtmSearchStr(window.location.search);
        const gtm_client_id = parseClientIdFromSearchString(window.location.search);
        const cleaningKey = parseCleaningPlan(window.location.search);
        const storageKey = parseStoragePlan(window.location.search);
        if (cleaningKey) {
            setCleaningSpace(cleaningKey);
            setService(ServiceKeys.CLEANING);
        }
        if (storageKey) {
            setStorageSpace(storageKey);
            setService(ServiceKeys.STORAGE);
        }
        setUtmParams(utmMarks);
        setGtmClientId(gtm_client_id);
        return () => { };
    }, []);
    const parseId = (e) => {
        const id = parseClientIdFromPm(e);
        if (!clientId && id) {
            setGtmClientId(id);
        }
    };
    useEffect(() => {
        window.addEventListener('message', parseId);
        return () => {
            window.removeEventListener('message', parseId);
        };
    }, []);
    useEffect(() => {
        // amplitude-analytics
        trackEvent(Events.GET_A_QUOTE_OPENED);
    }, []);
    useIframePm();
    return (React.createElement("div", { className: "checkout-page " },
        React.createElement(QuoteWrapper, { quoteTitle: "Get a quote", currentSlide: currentSlide, slideTitle: slidesMap[currentSlide - 1][1].title, progress: Math.ceil((currentSlide / totalSlides) * 100) }, slidesMap.map(([, slide], index) => currentSlide === index + 1 && slide.component))));
};
export default observer(CheckOut);
