export var UTMKeys;
(function (UTMKeys) {
    UTMKeys["utm_term"] = "utm_term";
    UTMKeys["utm_campaign"] = "utm_campaign";
    UTMKeys["utm_content"] = "utm_content";
    UTMKeys["utm_source"] = "utm_source";
})(UTMKeys || (UTMKeys = {}));
export var UTMStudentKeys;
(function (UTMStudentKeys) {
    UTMStudentKeys["utm_medium"] = "utm_medium";
    UTMStudentKeys["utm_campaign"] = "utm_campaign";
    UTMStudentKeys["utm_source"] = "utm_source";
})(UTMStudentKeys || (UTMStudentKeys = {}));
export const UAevents = {
    MOVING: 'moving_quote',
    STORAGE: 'storage_quote',
    SHIPPING: 'shipping_quote',
    CLEANING: 'cleaning_quote',
};
export const defaultErrorsObj = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    phonePrefix: '',
    university: '',
    job_title: '',
};
