import styled from 'styled-components';
import MainColors from '../../shared/DesignColors';
export const TextContainer = styled.div `
  display: flex;
  flex-direction: column;
  color: '#F0512D';
  max-width: 180px;
  b {
    font-size: 16px;
    line-height: 22px;
    color: #1b1b1e;

    ${({ $disabled }) => $disabled &&
    `
    color: ${MainColors.secondary};
    `}
  }
  span {
    font-size: 12px;
    line-height: 16px;

    ${({ $disabled }) => $disabled &&
    `
    color: ${MainColors.secondary};
    `}
  }
`;
