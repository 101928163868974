import useStores from '#Hooks/useStores';
import { storageTerms } from '#Types/newQuoteTypes';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FlexItemsContainer, Subtitle, Title, TitleClarify } from '../../shared/Components';
import StorageDurationItem from './StorageDurationItem';
import ModuleError from '../../shared/ModuleError';
const StorageDuration = () => {
    const { checkoutData: { storageTerm, setStorageTerm, isStudent, isStudentPromo }, errorState: { isNoStorageTermError, setEmptyStorageTermError }, } = useStores();
    const onStorageTermSelect = (k) => {
        setStorageTerm(k);
        setEmptyStorageTermError();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null,
            `For how long do you want to store?`,
            React.createElement(TitleClarify, null, "For storage")),
        React.createElement(Subtitle, null, 'The longer you store, the more you save.'),
        React.createElement(ModuleError, { opened: isNoStorageTermError }, "Please set for how long you want to store"),
        React.createElement(FlexItemsContainer, null, Object.entries(storageTerms).map(([key, card]) => (React.createElement(StorageDurationItem, { discount: card.discount, key: key, title: card.title, onClick: () => onStorageTermSelect(key), isChecked: storageTerm === key, isStudent: isStudent.value === 'true' || isStudentPromo }))))));
};
export default observer(StorageDuration);
