import useStores from '#Hooks/useStores';
import { ServiceKeys } from '#Types/newQuoteTypes';
import { parseStorageTerm, parseCleaningPlan, parseIsMoving, parseStoragePlan, parseClientIdFromPm, parseClientIdFromSearchString, parseIsStudent, parseIsStudentPromo, } from '#Utils/queryStrParsers';
import parseUtmSearchStr from '#Utils/parseUtmSearchStr';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import QuoteWrapper from './components/shared/QuoteWrapper';
import Toast from './components/shared/Toast';
import { ContactDetails, DateAndTime, Final, Plans, Services } from './components/slides';
import useIframePm from '#Hooks/useIframePm';
const NewCheckout = () => {
    const { checkoutData: { currentSlide, setUtmParams, setGtmClientId, setStorageSpace, setCleaningSpace, setService, setStorageTerm, setCurrentSlide, setIsStudentPromo, setIsStudent, clientId, isPlansNeeded, }, appData: { error }, } = useStores();
    const slidesToRender = {
        service: {
            component: React.createElement(Services, { key: "service" }),
        },
        ...(isPlansNeeded && {
            plans: {
                component: React.createElement(Plans, { key: "plans" }),
            },
        }),
        dateAndTime: {
            component: React.createElement(DateAndTime, { key: "dateAndTime" }),
        },
        contactDetails: {
            component: React.createElement(ContactDetails, { key: "contactDetails" }),
        },
        final: {
            component: React.createElement(Final, { key: "final" }),
        },
    };
    const slidesMap = Object.entries(slidesToRender);
    useEffect(() => {
        const utmMarks = parseUtmSearchStr(window.location.search);
        const gtm_client_id = parseClientIdFromSearchString(window.location.search);
        const cleaningKey = parseCleaningPlan(window.location.search);
        const storageKey = parseStoragePlan(window.location.search);
        const isMoving = parseIsMoving(window.location.search);
        const isStudent = parseIsStudent(window.location.search);
        const isStudentPromo = parseIsStudentPromo(window.location.search);
        if (cleaningKey) {
            setCleaningSpace(cleaningKey);
            setService(ServiceKeys.CLEANING);
        }
        if (storageKey) {
            setService(ServiceKeys.STORAGE);
            setStorageSpace(storageKey);
            const storageTermKey = parseStorageTerm(window.location.search);
            setStorageTerm(storageTermKey);
        }
        if (isMoving) {
            setService(ServiceKeys.MOVING);
        }
        if (isStudent) {
            setIsStudent({ value: 'true', label: 'Yes, I am' });
        }
        if (isStudentPromo) {
            if (!storageKey) {
                setService(ServiceKeys.STORAGE);
                setCurrentSlide('plans');
            }
            setIsStudentPromo(true);
        }
        if (cleaningKey || storageKey || isMoving) {
            setCurrentSlide('dateAndTime');
        }
        setUtmParams(utmMarks);
        setGtmClientId(gtm_client_id);
        return () => { };
    }, []);
    const parseId = (e) => {
        const id = parseClientIdFromPm(e);
        if (!clientId && id) {
            setGtmClientId(id);
        }
    };
    useEffect(() => {
        window.addEventListener('message', parseId);
        return () => {
            window.removeEventListener('message', parseId);
        };
    }, []);
    useIframePm();
    return (React.createElement(React.Fragment, null,
        React.createElement(QuoteWrapper, { isFinalSlide: currentSlide === 'final' }, slidesMap.map(([key, slide]) => currentSlide === key && slide.component)),
        React.createElement(Toast, { text: " Oops, something went wrong", show: !!error, type: "error" })));
};
export default observer(NewCheckout);
