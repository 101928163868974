import { StorageTermKeysToTerm } from './../types/quoteTypes';
import { makeAutoObservable } from 'mobx';
import { cleaningPrices, ServiceKeys, StorageSizes, } from '#Types/quoteTypes';
import AppData from './AppData';
import dayjs from 'dayjs';
class QuoteData {
    currentSlide = 1;
    totalSlides = 0;
    services = [];
    addresses = {
        collection: { address: '', postcode: '' },
        delivery: { address: '', postcode: '' },
        cleaning: { address: '', postcode: '' },
    };
    country = '';
    storageSpace = null;
    storageTerm = null;
    cleaningSpace = null;
    dates = {
        movingDate: new Date(),
        cleaningDate: new Date(),
    };
    details = { email: '', phone: '', phonePrefix: '', firstName: '', lastName: '' };
    promoCode = '';
    utmParams = {};
    clientId = '';
    constructor() {
        makeAutoObservable(this);
    }
    get isShipping() {
        return this.services.includes(ServiceKeys.SHIPPING);
    }
    get isStorage() {
        return this.services.includes(ServiceKeys.STORAGE);
    }
    get isMoving() {
        return this.services.includes(ServiceKeys.MOVING);
    }
    get isCleaning() {
        return this.services.includes(ServiceKeys.CLEANING);
    }
    get isOnlyCleaning() {
        return this.services.includes(ServiceKeys.CLEANING) && this.services.length === 1;
    }
    setUtmParams = (obj) => {
        this.utmParams = obj;
    };
    setGtmClientId = (id) => {
        this.clientId = id;
    };
    setTotalSlides = (slides) => {
        this.totalSlides = slides;
    };
    setNextSlide = () => {
        this.currentSlide += 1;
    };
    setPrevSlide = () => {
        this.currentSlide -= 1;
    };
    setService = (key) => {
        if (this.services.includes(key)) {
            this.services = this.services.filter(item => item !== key);
        }
        else {
            this.services.push(key);
        }
    };
    setAddress = (addr, key) => {
        this.addresses[key] = { postcode: addr.postcode, address: addr.address };
    };
    setCountry = (country) => {
        this.country = country;
    };
    setStorageSpace = (key) => {
        this.storageSpace = key;
    };
    setStorageTerm = (key) => {
        this.storageTerm = key;
    };
    setCleaningSpace = (key) => {
        this.cleaningSpace = key;
    };
    setDate = (date, key) => {
        this.dates[key] = date;
    };
    setDetails = (details) => {
        this.details = details;
    };
    setPromo = (code) => {
        this.promoCode = code;
    };
    sendData = () => {
        const allData = {
            services: this.services,
            services_info: {
                ...((this.isMoving || this.isShipping || this.isStorage) && {
                    date: this.dates.movingDate?.toISOString().substring(0, 10),
                    collection_address: this.addresses.collection,
                }),
                ...(this.isMoving && { delivery_address: this.addresses.delivery }),
                ...(this.isShipping && { delivery_country: this.country }),
                ...(this.isStorage && { storage_plan: this.storageSpace && StorageSizes[this.storageSpace] }),
                ...(this.isStorage && {
                    storage_due_date: dayjs(this.dates.movingDate)
                        .add(StorageTermKeysToTerm[this.storageTerm || 'monthly'], 'week')
                        .toISOString()
                        .substring(0, 10),
                }),
                ...(this.isCleaning && {
                    cleaning_address: this.addresses.cleaning,
                    cleaning_date: this.dates.cleaningDate?.toISOString().substring(0, 10),
                    cleaning_plan: {
                        amount_of_cleaners: this.cleaningSpace && cleaningPrices[this.cleaningSpace].cleaners,
                        hours: this.cleaningSpace && cleaningPrices[this.cleaningSpace].hours,
                        ...(this.cleaningSpace === 'custom' && { custom: true }),
                    },
                }),
            },
            contact_info: {
                email: this.details.email,
                phone: `+${this.details.phonePrefix}${this.details.phone}`,
                first_name: this.details.firstName,
                last_name: this.details.lastName,
            },
            promo_code: this.promoCode,
            utm: this.utmParams,
            gta_client_id: this.clientId,
        };
        return AppData.sendData(allData);
    };
}
export default new QuoteData();
