import './buttonsBlock.scss';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Button, SocialsBlock } from '#Components/index';
import { ButtonKinds } from '#Components/shared/Button/Button';
import useStores from '#Hooks/useStores';
const ButtonsBlock = ({ onNextClick, errorText, submitTitle }) => {
    const { quoteData: { currentSlide, totalSlides, setPrevSlide }, appData: { loading }, } = useStores();
    const onNextBtnClick = () => {
        onNextClick();
    };
    const isLastSlide = currentSlide === totalSlides;
    const isFinalSlide = currentSlide === totalSlides - 1;
    return (React.createElement(React.Fragment, null,
        errorText && React.createElement("span", { className: "button-block-error" }, errorText),
        React.createElement("div", { className: `button-block ${isLastSlide ? 'button-block_content-center' : ''}` }, !isLastSlide ? (React.createElement(React.Fragment, null,
            currentSlide > 1 && React.createElement(Button, { title: "Back", onClick: setPrevSlide }),
            isFinalSlide ? (React.createElement(Button, { submit: true, isLoading: loading, title: submitTitle || 'Submit', onClick: onNextBtnClick, kind: ButtonKinds.primary })) : (React.createElement(Button, { title: "Next", onClick: onNextBtnClick, kind: ButtonKinds.primary })))) : (React.createElement(React.Fragment, null,
            React.createElement("a", { target: "_top", href: "https://www.stackt.co.uk/" },
                React.createElement(Button, { title: "Back to Home Page", onClick: () => { }, kind: ButtonKinds.primary })),
            React.createElement(SocialsBlock, null))))));
};
export default observer(ButtonsBlock);
