import './storageTerm.scss';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ButtonsBlock, Carousel, StorageTermCard } from '#Components';
import useEnterClick from '#Hooks/useEnterClick';
import useStores from '#Hooks/useStores';
import { storageTerms } from '#Types/quoteTypes';
import { Events, trackEvent } from '#Utils/amplitudeAnalyticsService';
import postMessageToWebflow from '#Utils/postMessageWebflow';
const StorageTerm = ({ isRequired }) => {
    const [error, setError] = useState('');
    const { quoteData: { setStorageTerm, storageTerm, setNextSlide }, } = useStores();
    const onStorageSpaceSelect = (k) => {
        setStorageTerm(k);
        // amplitude-analytics
        trackEvent(Events.STORAGE_SPACE_SELECTED, { type: k });
    };
    const onNextClick = () => {
        if (isRequired && storageTerm) {
            setNextSlide();
            postMessageToWebflow('nextClick', 'click');
            return;
        }
        setError(`Please choose one plan`);
        setTimeout(() => {
            setError('');
        }, 4000);
        // amplitude-analytics
        trackEvent(Events.STORAGE_SPACE_ERROR);
    };
    useEnterClick(onNextClick);
    useEffect(() => {
        // amplitude-analytics
        trackEvent(Events.STORAGE_SPACE_OPENED);
    }, []);
    const storageTermCards = Object.entries(storageTerms).map(([key, card]) => (React.createElement("div", { style: { display: 'flex' } },
        React.createElement(StorageTermCard, { discount: card.discount, term: card.term, key: key, title: card.title, onClick: () => onStorageSpaceSelect(key), isChecked: storageTerm === key }),
        React.createElement("div", { style: { width: 10, height: 360 } }))));
    const currentSlideIndex = Object.entries(storageTerms).findIndex(([key, item]) => storageTerm === key);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "storage-term-slide" },
            React.createElement("h4", null, "The longer you store, the more you save."),
            React.createElement("div", { className: "storage-term-slide__body" }, isMobile ? (React.createElement("div", { className: "storage-term-slide__carousel-container" },
                React.createElement(Carousel, { selectedIndex: currentSlideIndex > 0 ? currentSlideIndex : 0, items: storageTermCards }))) : (storageTermCards))),
        React.createElement(ButtonsBlock, { onNextClick: onNextClick, errorText: error })));
};
export default observer(StorageTerm);
