import useStores from '#Hooks/useStores';
import React, { useEffect } from 'react';
import { AddressModule, AreYouStudent, CountryModule, DateModule } from '../../modules';
import { HR } from '../../shared/Components';
import { NewEvents, trackNewEvent } from '#Utils/amplitudeAnalyticsService';
const DateAndTime = () => {
    const { checkoutData: { isCleaning, isStorage, isMoving, isShipping, isStorageReturn, isPlansNeeded, isStudentPromo }, } = useStores();
    const AddressModulesToRender = [
        (isMoving || isShipping) && React.createElement(AddressModule, { kind: "collection" }),
        isStorage && React.createElement(AddressModule, { kind: "storage_collection" }),
        isCleaning && React.createElement(AddressModule, { kind: "cleaning" }),
        (isMoving || isStorageReturn) && React.createElement(AddressModule, { kind: "delivery" }),
        isShipping && React.createElement(CountryModule, { kind: "delivery" }),
    ];
    const DateModulesToRender = [
        ...(isMoving ? [React.createElement(DateModule, { kind: "movingDate" })] : []),
        ...(isStorage ? [React.createElement(DateModule, { kind: "storageCollectionDate" })] : []),
        ...(isCleaning ? [React.createElement(DateModule, { kind: "cleaningDate" })] : []),
        ...(isShipping ? [React.createElement(DateModule, { kind: "shippingDate" })] : []),
        ...(isStorageReturn ? [React.createElement(DateModule, { kind: "deliveryDate" })] : []),
    ];
    useEffect(() => {
        trackNewEvent(NewEvents.checkoutv2_stage3_opened);
    }, []);
    return (React.createElement(React.Fragment, null,
        !isPlansNeeded && !isStudentPromo && (React.createElement(React.Fragment, null,
            React.createElement(AreYouStudent, null),
            React.createElement(HR, null))),
        AddressModulesToRender.map((module, index) => (React.createElement(React.Fragment, { key: `addr_${index}` },
            module,
            module && React.createElement(HR, null)))),
        DateModulesToRender.map((module, index) => (React.createElement(React.Fragment, { key: `date_${index}` },
            module,
            module && DateModulesToRender.length !== index + 1 && React.createElement(HR, null))))));
};
export default DateAndTime;
