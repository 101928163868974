import './cleaningPrice.scss';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ButtonsBlock, Carousel, CleaningPriceCard } from '#Components';
import useEnterClick from '#Hooks/useEnterClick';
import useStores from '#Hooks/useStores';
import { cleaningPrices } from '#Types/quoteTypes';
import { Events, trackEvent } from '#Utils/amplitudeAnalyticsService';
import postMessageToWebflow from '#Utils/postMessageWebflow';
const CleaningPrice = ({ isRequired }) => {
    const [error, setError] = useState('');
    const { quoteData: { setCleaningSpace, cleaningSpace, setNextSlide }, } = useStores();
    const onSetCleaningSpace = (s) => {
        setCleaningSpace(s);
        // amplitude-analytics
        trackEvent(Events.PROPERTY_TYPE_SELECTED, { type: s });
    };
    const onNextClick = () => {
        if (isRequired && cleaningSpace) {
            setNextSlide();
            postMessageToWebflow('nextClick', 'click');
            return;
        }
        setError(`Please choose at least one cleaning plan`);
        setTimeout(() => {
            setError('');
        }, 4000);
        // amplitude-analytics
        trackEvent(Events.PROPERTY_TYPE_ERROR);
    };
    useEnterClick(onNextClick);
    useEffect(() => {
        // amplitude-analytics
        trackEvent(Events.PROPERTY_TYPE_OPENED);
    }, []);
    const cleaningCards = Object.entries(cleaningPrices).map(([key, service]) => (React.createElement("div", { style: { display: 'flex' } },
        React.createElement(CleaningPriceCard, { description: service.description, extraFee: service.extraFee, cleaners: service.cleaners, hours: service.hours, price: service.price, key: key, title: service.title, onClick: () => onSetCleaningSpace(key), isChecked: cleaningSpace === key, iconUrl: service.iconUrl }),
        React.createElement("div", { style: { width: 10, height: 360 } }))));
    const currentSlideIndex = Object.entries(cleaningPrices).findIndex(([key, item]) => cleaningSpace === key);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "cleaning-space-slide" },
            React.createElement("h4", null, "Please note that our cleaners would bring cleaning materials to carry out the service, however, you are required to make sure there is a vacuum cleaner available for them to use throughout the cleaning process."),
            React.createElement("div", { className: "cleaning-space-slide__body" }, isMobile ? (React.createElement(Carousel, { selectedIndex: currentSlideIndex > 0 ? currentSlideIndex : 0, items: cleaningCards })) : (cleaningCards))),
        React.createElement(ButtonsBlock, { onNextClick: onNextClick, errorText: error })));
};
export default observer(CleaningPrice);
