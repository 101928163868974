import React from 'react';
// import Option from '#Types/Option';
import { RadioCheckmark, RadioContainer, RadioGroup } from './Components';
function RadioRow({ value, options, onChange, isDisabled, direction = 'row', radioName, }) {
    const onChangeValue = (e, label) => {
        const val = e.target.value;
        onChange({ value: val, label });
    };
    return (React.createElement(RadioGroup, { direction: direction }, options.map(item => (React.createElement(RadioContainer, { key: item.label },
        item.label,
        React.createElement("input", { value: item.value, disabled: isDisabled, onChange: e => onChangeValue(e, item.label), type: "radio", checked: value?.value === item.value, name: radioName }),
        React.createElement(RadioCheckmark, null))))));
}
export default RadioRow;
