import './movers-quote.scss';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { QuoteWrapper } from '#Components';
import { MoverQuoteCredentials, MoverQuoteFinal } from '#Components/slides';
import useStores from '#Hooks/useStores';
import useIframePm from '#Hooks/useIframePm';
const MoverQuote = () => {
    const { quoteData: { currentSlide, setTotalSlides, details }, } = useStores();
    const slidesToRender = {
        credentials: {
            title: "Welcome to the Stackt Movers Family! Let's get you on-board 🚚",
            component: React.createElement(MoverQuoteCredentials, { key: "credentials", isRequired: true }),
        },
        final: {
            title: `Thank you, ${details.firstName}! 🙌`,
            component: React.createElement(MoverQuoteFinal, { key: "final" }),
        },
    };
    const slidesMap = Object.entries(slidesToRender);
    const totalSlides = 2;
    useEffect(() => {
        setTotalSlides(totalSlides);
    }, []);
    useIframePm();
    return (React.createElement("div", { className: "checkout-page " },
        React.createElement(QuoteWrapper, { quoteTitle: "Become a mover", currentSlide: currentSlide, slideTitle: slidesMap[currentSlide - 1][1].title, progress: Math.ceil((currentSlide / totalSlides) * 100) }, slidesMap.map(([, slide], index) => currentSlide === index + 1 && slide.component))));
};
export default observer(MoverQuote);
