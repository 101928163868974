import styled from 'styled-components';
import MainColors from '../DesignColors';
export const StyledInput = styled.div `
  width: 100%;
  margin-bottom: ${({ bottomMargin }) => bottomMargin}px;
`;
export const Input = styled.input `
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    font-family: 'TT Travels', serif;
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
  }
  text-overflow: ellipsis;
  font-family: 'TT Travels', serif;
  transition: all 0.5s;
  font-size: 16px;
  line-height: 22px;
  background: none;
  width: 92%;
  max-width: 720px;
  font-weight: 400;
  border: none;

  position: relative;
  top: -16px;
  background: none;

  :focus {
    top: -8px;
  }

  :disabled {
    color: rgba(27, 27, 30, 0.5);
  }

  ${({ isFilled }) => isFilled &&
    `
      top: -8px;
    `}
`;
export const Container = styled.div `
  height: 66px;
  cursor: text;
  position: relative;
  border: 1px solid var(--main-grey);
  border-radius: 15px;
  padding: 12px;

  .input-icon {
    transition: all 0.25s;
    position: absolute;
    top: calc(50% - 8px);

    ${({ active }) => active &&
    `
    top: calc(50% - 2px);
    `}
  }

  ${({ error }) => error &&
    `
      border: 1px solid ${MainColors.primary};
    `}
  ${({ active, error }) => active &&
    !error &&
    `
      border: 1px solid ${MainColors.dark};
    `}

     ${({ disabled }) => disabled &&
    `
    background-color: #E6EBEF;
    border: 1px solid ${MainColors.secondary};
    `}
`;
export const Description = styled.p `
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;

  /* @media (max-width: 640px) {
    font-size: 12px;
    line-height: 16px;
  } */
`;
export const ErrorText = styled(Description) `
  color: ${MainColors.primary};
`;
export const Placeholder = styled.div `
  position: relative;
  transition: all 0.25s;
  color: #1b1b1e;
  opacity: 0.3;
  height: 25px;

  ${({ active }) => active
    ? `
    top: 0;
    font-size: 12px;
    line-height: 16px;
    `
    : `
    top: 12px;
    font-size: 16px;
    line-height: 22px;
    `}
`;
