import { makeAutoObservable, runInAction } from 'mobx';
import promiseRetry from 'promise-retry';
import ApiService from '#Services/apiService';
import QuoteData from './QuoteData';
import CheckoutData from './CheckoutData';
import postMessageToWebflow from '#Utils/postMessageWebflow';
const api = new ApiService();
class AppData {
    loading = false;
    error = null;
    constructor() {
        makeAutoObservable(this);
    }
    sendStudentData = async (data) => {
        try {
            runInAction(() => {
                this.loading = true;
            });
            await api.saveStudentData(data);
            QuoteData.setNextSlide();
        }
        catch (error) {
            runInAction(() => {
                this.error = 'Something went wrong, please contact our Customer Service hotline at 020 3697 0350.';
            });
        }
        finally {
            runInAction(() => {
                this.loading = false;
                setTimeout(() => {
                    this.error = null;
                }, 5000);
            });
        }
    };
    sendMoversData = async (data) => {
        try {
            runInAction(() => {
                this.loading = true;
            });
            await api.saveMoversData(data);
            QuoteData.setNextSlide();
        }
        catch (error) {
            runInAction(() => {
                this.error = 'Something went wrong, please contact our Customer Service hotline at 020 3697 0350.';
            });
        }
        finally {
            runInAction(() => {
                this.loading = false;
                setTimeout(() => {
                    this.error = null;
                }, 5000);
            });
        }
    };
    sendAffiliateData = async (data) => {
        try {
            runInAction(() => {
                this.loading = true;
            });
            await api.saveAffiliateData(data);
            QuoteData.setNextSlide();
        }
        catch (error) {
            runInAction(() => {
                this.error = 'Something went wrong, please contact our Customer Service hotline at 020 3697 0350.';
            });
        }
        finally {
            runInAction(() => {
                this.loading = false;
                setTimeout(() => {
                    this.error = null;
                }, 5000);
            });
        }
    };
    // sendData = (data: ICheckoutDto) => {
    //   runInAction(() => {
    //     this.loading = true;
    //   });
    //   return new Promise((resolve, rej) => {
    //     setTimeout(() => {
    //       console.log('data', data);
    //       resolve('ok');
    //       runInAction(() => {
    //         this.loading = false;
    //       });
    //     }, 3000);
    //   });
    // };
    sendData = (data) => promiseRetry(
    // eslint-disable-next-line consistent-return
    async (retry, n) => {
        runInAction(() => {
            this.loading = true;
        });
        await api.saveData(data).catch(e => {
            runInAction(() => {
                this.error = `An error occurred, trying to resend (attempt ${n})...`;
            });
            retry(e);
        });
    }, { minTimeout: 5000, retries: 0, factor: 2 })
        .then(() => {
        CheckoutData.setCurrentSlide('final');
        postMessageToWebflow('submitClick', 'submit');
    })
        .catch(() => {
        runInAction(() => {
            this.error = 'Something went wrong, please contact our Customer Service hotline at 020 3697 0350.';
        });
    })
        .finally(() => {
        runInAction(() => {
            this.loading = false;
            setTimeout(() => {
                this.error = null;
            }, 10000);
        });
    });
}
export default new AppData();
