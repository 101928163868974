import './formInput.scss';
import React, { useEffect, useRef, useState } from 'react';
import ErrorBar from './ErrorBar';
const FormInput = ({ onChange, name, value, type = 'text', description, errorText, ref, ariaLabel }) => {
    const [active, setActive] = useState(false);
    const inputRef = useRef(null);
    const slide = () => {
        if (!value) {
            setActive(!active);
        }
    };
    useEffect(() => {
        if (value)
            setActive(true);
    }, [value]);
    const onContainerClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    return (React.createElement("div", { className: 'form-input' },
        React.createElement("div", { className: 'form-input__wrapper' },
            React.createElement("div", { onClick: onContainerClick, className: "form-input__container" },
                React.createElement("div", { className: `form-input__placeholder ${active ? 'form-input__placeholder_active' : 'form-input__placeholder_inactive'}` }, name),
                React.createElement("input", { "aria-label": ariaLabel, id: 'form-input', style: { background: 'none' }, ref: ref || inputRef, className: "form-input__field", onBlur: slide, onFocus: slide, value: value, onChange: e => onChange(e.target.value), type: type }),
                !!errorText && React.createElement(ErrorBar, { text: errorText })),
            !!description && React.createElement("p", { className: "form-input__description" }, description))));
};
export default FormInput;
