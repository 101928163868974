import useStores from '#Hooks/useStores';
import { cleaningPrices } from '#Types/newQuoteTypes';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FlexItemsContainer, Subtitle, Title, TitleClarify } from '../../shared/Components';
import { FootNote } from './Components';
import PropertyTypeItem from './PropertyTypeItem';
import ModuleError from '../../shared/ModuleError';
const PropertyType = () => {
    const { checkoutData: { setCleaningSpace, cleaningSpace }, errorState: { isNoCleaningPropertyError, setEmptyCleaningPlanError }, } = useStores();
    const onCleaningSpaceSelect = (k) => {
        setCleaningSpace(k);
        setEmptyCleaningPlanError();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null,
            `Choose a property type`,
            React.createElement(TitleClarify, null, "For cleaning")),
        React.createElement(Subtitle, null, 'Please note that our cleaners would bring cleaning materials to carry out the service, however, you are required to make sure there is a vacuum cleaner available for them to use throughout the cleaning process.'),
        React.createElement(ModuleError, { opened: isNoCleaningPropertyError }, "Please select cleaning plan"),
        React.createElement(FlexItemsContainer, null, Object.entries(cleaningPrices).map(([key, card]) => (React.createElement(PropertyTypeItem, { cleaners: card.cleaners, extraFee: card.extraFee, hours: card.hours, title: card.title, price: card.price, key: key, onClick: () => onCleaningSpaceSelect(key), isChecked: cleaningSpace === key })))),
        React.createElement(FootNote, null, "*Extra hourly fee")));
};
export default observer(PropertyType);
