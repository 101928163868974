import { makeAutoObservable } from 'mobx';
import AppData from './AppData';
class StudentData {
    details = {
        email: '',
        phone: '',
        phonePrefix: '',
        firstName: '',
        lastName: '',
        university: 'UCL',
    };
    utmParams = {};
    clientId = '';
    constructor() {
        makeAutoObservable(this);
    }
    setDetails = (details) => {
        this.details = details;
    };
    setUtmParams = (obj) => {
        this.utmParams = obj;
    };
    setGtmClientId = (id) => {
        this.clientId = id;
    };
    sendStudentData = () => {
        const allData = {
            contact_info: {
                email: this.details.email,
                phone: `+${this.details.phonePrefix}${this.details.phone}`,
                first_name: this.details.firstName,
                last_name: this.details.lastName,
                student: true,
            },
            university: this.details.university,
            utm: this.utmParams,
            gta_client_id: this.clientId,
        };
        return AppData.sendStudentData(allData);
    };
}
export default new StudentData();
