import * as React from 'react';
import AppData from '#Stores/AppData';
import QuoteData from '#Stores/QuoteData';
import MoverData from '#Stores/MoverData';
import StudentData from '#Stores/StudentData';
import CheckoutData from '#Stores/CheckoutData';
import ErrorState from '#Stores/ErrorState';
import AffiliateData from '#Stores/AffiliateData';
export const storesContext = React.createContext({
    quoteData: QuoteData,
    appData: AppData,
    moverData: MoverData,
    studentData: StudentData,
    checkoutData: CheckoutData,
    errorState: ErrorState,
    affiliateData: AffiliateData,
});
