import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { ButtonsBlock, DatePicker } from '#Components';
import useEnterClick from '#Hooks/useEnterClick';
import useStores from '#Hooks/useStores';
import { getDateOpenedKey, getDateSelectedKey, trackEvent } from '#Utils/amplitudeAnalyticsService';
import postMessageToWebflow from '#Utils/postMessageWebflow';
const DateSlide = ({ isRequired, kind }) => {
    const { quoteData: { setDate, dates, setNextSlide }, } = useStores();
    const onSelectDate = (d, k) => {
        setDate(d, k);
        // amplitude-analytics
        trackEvent(getDateSelectedKey(kind));
    };
    const onNextClick = () => {
        if (isRequired && dates[kind]) {
            setNextSlide();
            postMessageToWebflow('nextClick', 'click');
        }
    };
    useEnterClick(onNextClick);
    useEffect(() => {
        // amplitude-analytics
        trackEvent(getDateOpenedKey(kind));
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "datepicker" },
            React.createElement(DatePicker, { value: dates[kind], onChange: date => onSelectDate(date, kind) })),
        React.createElement(ButtonsBlock, { onNextClick: onNextClick })));
};
export default observer(DateSlide);
