import { cleaningMatching, monthsToTermKey, storageMatching, } from '#Types/quoteTypes';
import origins from '#Constants/origins';
export const parseCleaningPlan = (str) => {
    const urlSearchParams = new URLSearchParams(str);
    const allParams = Object.fromEntries(urlSearchParams.entries());
    const cleaning = allParams.cleaning;
    if (cleaning) {
        return cleaningMatching[cleaning];
    }
    return null;
};
export const parseClientIdFromSearchString = (str) => {
    const urlSearchParams = new URLSearchParams(str);
    const allParams = Object.fromEntries(urlSearchParams.entries());
    const id = allParams.gtm_client_id || '';
    return id;
};
export const parseClientIdFromPm = (e) => {
    const { origin } = e;
    if (!origins.includes(origin)) {
        return null;
    }
    const data = typeof e.data === 'object' ? e.data : JSON.parse(e.data);
    if (data.type === 'cl_id') {
        return data.message;
    }
    return null;
};
export const parseIsMoving = (str) => {
    const urlSearchParams = new URLSearchParams(str);
    const allParams = Object.fromEntries(urlSearchParams.entries());
    const isMoving = allParams.moving;
    return !!isMoving;
};
export const parseIsStudent = (str) => {
    const urlSearchParams = new URLSearchParams(str);
    const allParams = Object.fromEntries(urlSearchParams.entries());
    const isStudent = allParams.student;
    return !!isStudent;
};
export const parseStorageTerm = (str) => {
    const urlSearchParams = new URLSearchParams(str);
    const allParams = Object.fromEntries(urlSearchParams.entries());
    const term = allParams.term;
    if (term) {
        return monthsToTermKey[parseInt(term)];
    }
    return null;
};
export const parseIsStudentPromo = (str) => {
    const urlSearchParams = new URLSearchParams(str);
    const allParams = Object.fromEntries(urlSearchParams.entries());
    const isStudentPromo = allParams.student_promo;
    return !!isStudentPromo;
};
export const parseStoragePlan = (str) => {
    const urlSearchParams = new URLSearchParams(str);
    const allParams = Object.fromEntries(urlSearchParams.entries());
    const storage = allParams.storage;
    if (storage && storageMatching[storage]) {
        return storageMatching[storage];
    }
    return null;
};
