import './storageSpaceCard.scss';
import classNames from 'classnames';
import React from 'react';
import InlineSVG from 'react-inlinesvg/esm';
import { BoxIcon, CheckMark } from '#Images';
const StorageSpaceCard = ({ title, imgUrl, isChecked, onClick, price, size, sqft }) => (React.createElement("div", { onClick: onClick, className: classNames('storage-card', { 'storage-card_checked': isChecked }) },
    isChecked && (React.createElement("div", { className: "storage-card__icon" },
        React.createElement(InlineSVG, { src: CheckMark }))),
    React.createElement("img", { src: imgUrl, className: "storage-card__storage-space-icon", alt: "storage-space-icon" }),
    React.createElement("span", { className: "storage-card__sqft" }, sqft === 'Custom' ? 'Custom' : `${sqft} ft²`),
    React.createElement("span", { className: "storage-card__title" }, title),
    React.createElement("span", { className: "storage-card__size" },
        !!price && size && React.createElement("img", { src: BoxIcon, className: "storage-card__box-icon", alt: "box-icon" }),
        size),
    !!price && (React.createElement("div", { className: "storage-card__price-wrapper" },
        React.createElement("span", { className: classNames('storage-card__price') }, price),
        React.createElement("span", { className: "storage-card__period" },
            "/ ",
            sqft === 'Custom' ? 'ft²' : 'week')))));
export default StorageSpaceCard;
