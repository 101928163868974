import styled from 'styled-components';
import MainColors, { Colors } from './DesignColors';
export const Title = styled.h1 `
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;

  @media (max-width: 640px) {
    font-size: 20px;
    line-height: 28px;
  }
`;
export const TitleClarify = styled.span `
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  margin-left: auto;

  @media (max-width: 640px) {
    display: none;
  }
`;
export const Subtitle = styled.div `
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 40px;

  @media (max-width: 640px) {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 32px;
  }
`;
export const StyledModuleError = styled.div `
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
  color: ${MainColors.primary};
  /* animation: inAnimation 270ms ease-out; */

  @keyframes inAnimation {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes outAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
`;
export const CheckBoxContainer = styled.div `
  align-items: baseline;
  margin-top: 16px;
  display: flex;
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 22px;

  @media (max-width: 640px) {
    margin-top: 10px;
  }

  input {
    top: 3px;
    margin-right: 10px;
    flex: none;
  }
`;
export const Checkbox = styled.input `
  height: 18px;
  width: 18px;
  cursor: pointer;

  border-radius: 2px;
  border: 2px solid ${props => (props.primary ? MainColors.primary : MainColors.dark)};
  color: hsl(0, 0%, 20%);
  position: relative;

  &:hover {
    border-color: ${MainColors.primary};
  }

  &:checked {
    background-color: ${MainColors.primary};
    border: none;
    &:hover {
      border-color: ${MainColors.secondary};
    }
    &:after {
      position: absolute;
      display: table;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      transition: all 0.2s ease;
      content: ' ';
    }
  }

  &:disabled {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 80%);
    cursor: initial;
    &:hover {
      border-color: #cdd7e0;
    }
  }

  &:after {
    top: 48%;
    left: calc(50% - 5px);
    width: 6px;
    height: 10px;
    content: ' ';
  }
`;
export const RadioGroup = styled.div `
  display: flex;
  flex-direction: ${props => props.direction};
`;
export const RadioContainer = styled.label `
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  font-family: 'TT Travels';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  :not(:last-child) {
    margin-right: 26px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  :hover input ~ span {
    background-color: #ccc;
  }

  input:checked ~ span {
    background-color: ${MainColors.primary};
    border: none;
  }

  input:disabled:checked ~ span {
    background-color: ${MainColors.secondary};
    :hover {
      background-color: ${MainColors.secondary};
    }
  }

  input:disabled ~ span {
    background-color: #fff;
    :hover {
    }
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
`;
export const RadioCheckmark = styled.span `
  position: absolute;
  border: 2px solid ${MainColors.secondary};
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  :after {
    content: '';
    position: absolute;
    display: none;
  }
`;
export const ItemContainer = styled.div `
  max-width: 232px;
  min-height: 66px;
  width: 100%;
  display: flex;
  padding: 12px;
  align-items: center;
  cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
  border: 1px solid #cdd7e0;
  border-radius: 15px;

  svg {
    margin-left: auto;

    ${({ $disabled }) => $disabled &&
    `
          filter: opacity(0.2);
    `}
  }

  @media (max-width: 640px) {
    max-width: 100%;
    min-height: 152px;
    max-width: 158px;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;

    svg {
      margin-left: 0;
    }
  }

  ${({ isChecked }) => isChecked &&
    `
          background-color: #F0512D10;
          border: 1px solid var(--main-color);
    `}
`;
export const ColumnItemContainer = styled(ItemContainer) `
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;
export const FlexItemsContainer = styled.div `
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;
`;
export const HR = styled.hr `
  height: 1px;
  background-color: ${MainColors.secondary};
  border: none;
  margin-top: 56px;
  margin-bottom: 56px;

  @media (max-width: 640px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;
export const NewServiceButton = styled.button `
  width: 100%;
  font-family: 'TT Travels';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  border: 1px;
  padding: 0;
  margin: 0;
  padding: 8px 24px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  box-shadow: 0px 16px 24px rgba(116, 34, 15, 0.1);
  background-color: ${MainColors[Colors.secondary]};
`;
