import React, { useEffect } from 'react';
import DetailsModule from '../../modules/Details/DetailsModule';
import { NewEvents, trackNewEvent } from '#Utils/amplitudeAnalyticsService';
const ContactDetails = () => {
    useEffect(() => {
        trackNewEvent(NewEvents.checkoutv2_stage4_opened);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(DetailsModule, null)));
};
export default ContactDetails;
