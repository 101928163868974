import './pickServices.scss';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import ButtonsBlock from '#Components/ButtonsBlock/ButtonsBlock';
import ServiceCard from '#Components/shared/ServiceCard/ServiceCard';
import useEnterClick from '#Hooks/useEnterClick';
import useStores from '#Hooks/useStores';
import { mainServices, ServiceKeys } from '#Types/quoteTypes';
import { Events, MapKeysToItems, trackEvent } from '#Utils/amplitudeAnalyticsService';
import postMessageToWebflow from '#Utils/postMessageWebflow';
const PickServices = ({ isRequired }) => {
    const [error, setError] = useState('');
    const { quoteData: { setService, services, setNextSlide }, } = useStores();
    const onNextClick = () => {
        if (isRequired && services.length) {
            setNextSlide();
            postMessageToWebflow('nextClick', 'click');
            return;
        }
        if (!services.length) {
            setError(`Please choose at least one service`);
            setTimeout(() => {
                setError('');
            }, 4000);
            // amplitude-analytics
            trackEvent(Events.GET_A_QUOTE_ITEM_SELECTED);
        }
    };
    const onSetService = (s) => {
        // amplitude-analytics
        if (!services.includes(s)) {
            trackEvent(Events.GET_A_QUOTE_ITEM_SELECTED, { item: MapKeysToItems[ServiceKeys[s]] });
        }
        setService(s);
    };
    useEnterClick(onNextClick);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "pick-service-slide" },
            React.createElement("h4", null, "Choose as many as you like:"),
            React.createElement("div", { className: "pick-service-slide__body" }, Object.entries(mainServices).map(([key, service]) => (React.createElement(ServiceCard, { key: key, title: service.title, Icon: service.iconUrl, onClick: () => onSetService(key), isChecked: services.includes(key) }))))),
        React.createElement(ButtonsBlock, { onNextClick: onNextClick, errorText: error })));
};
export default observer(PickServices);
