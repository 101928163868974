import './credentials.scss';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { ButtonsBlock, CheckBox, FormInput, FormInputPhone } from '#Components';
import useEnterClick from '#Hooks/useEnterClick';
import useStores from '#Hooks/useStores';
import { defaultErrorsObj } from '#Types/commonTypes';
import { validateCredentials } from '#Utils/validators';
const MoverQuoteCredentials = ({ isRequired }) => {
    const [errors, setErrors] = useState(defaultErrorsObj);
    const { moverData: { setDetails, details, sendMoverData }, appData: { error }, } = useStores();
    const onNextClick = () => {
        const formErrors = validateCredentials(details);
        const isAnyError = Object.values(formErrors).some(msg => !!msg);
        if (isRequired && details && !isAnyError) {
            sendMoverData();
            return;
        }
        setErrors(formErrors);
        setTimeout(() => {
            setErrors(defaultErrorsObj);
        }, 4000);
    };
    useEnterClick(onNextClick);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "credentials" },
            React.createElement("div", { className: "credentials__form-wrapper" },
                React.createElement("form", { className: "credentials__form" },
                    React.createElement(FormInput, { ariaLabel: "name", errorText: errors.firstName, name: "First name", onChange: firstName => setDetails({ ...details, firstName }), value: details.firstName }),
                    React.createElement(FormInput, { ariaLabel: "surname", errorText: errors.lastName, name: "Last name", onChange: lastName => setDetails({ ...details, lastName }), value: details.lastName }),
                    React.createElement(FormInputPhone, { ariaLabel: "phone", errorText: errors.phone, description: "We'll get back to you on WhatsApp.", name: "Phone number", onChange: phone => setDetails({ ...details, phone: phone.replace(/[^0-9]/g, '') }), onPrefixChange: phonePrefix => setDetails({ ...details, phonePrefix }), value: details.phone, type: "tel" }),
                    React.createElement(FormInput, { type: "email", ariaLabel: "email", errorText: errors.email, name: "Email", onChange: email => setDetails({ ...details, email }), value: details.email }),
                    React.createElement(FormInput, { ariaLabel: "vehicle", description: "If you don't have any \u2014 please leave it blank.", name: "Vehicle registration number", onChange: vehicleNumber => setDetails({ ...details, vehicleNumber }), value: details.vehicleNumber }),
                    React.createElement("div", { className: "checkbox-container" },
                        React.createElement("span", { className: "checkbox-container__title" },
                            React.createElement(CheckBox, { checked: details.hasTrolley, onChange: hasTrolley => setDetails({ ...details, hasTrolley }) }),
                            React.createElement("span", { style: { marginLeft: 15 } }, "I have a trolley \uD83D\uDED2")),
                        React.createElement("span", { className: "checkbox-container__description" }, "Please note that if you don't have a trolley, you will need to obtain one.")))),
            error && React.createElement("span", { className: "credentials__error" }, error)),
        React.createElement(ButtonsBlock, { onNextClick: onNextClick })));
};
export default observer(MoverQuoteCredentials);
