import useStores from '#Hooks/useStores';
import { mainServices } from '#Types/newQuoteTypes';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FlexItemsContainer, Subtitle, Title } from '../../shared/Components';
import ServiceItem from './ServiceItem';
import ModuleError from '../../shared/ModuleError';
import { NewEvents, trackNewEvent } from '#Utils/amplitudeAnalyticsService';
const Service = () => {
    const { checkoutData: { setService, services, isMovedFromService, isMovingAndCollection, isStorageAndReturn, setIsServiceChanged, }, errorState: { isEmptyServiceError, isOneMoreServiceError, setEmptyServiceError, setOneMoreServiceError }, } = useStores();
    const onSetService = (s) => {
        if (isMovedFromService) {
            setIsServiceChanged(true);
            trackNewEvent(NewEvents.checkoutv2_add_a_service_after_comming_back_to_the_first_step);
        }
        setService(s);
        setEmptyServiceError(false);
        setOneMoreServiceError();
    };
    useEffect(() => {
        trackNewEvent(NewEvents.checkoutv2_stage1_opened);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null, "How can we help you?"),
        React.createElement(Subtitle, null, "Choose as many as you like."),
        React.createElement(ModuleError, { opened: isEmptyServiceError && !isOneMoreServiceError }, "Please choose at least one service"),
        !isStorageAndReturn && isMovingAndCollection ? (React.createElement(ModuleError, { opened: true }, "Please note that the Storage Collection service already includes moving your items to our storage facility. Double-check if you need a Moving service separately.")) : isStorageAndReturn ? (React.createElement(ModuleError, { opened: true }, "Since you are not storing with us yet, we are unable to book the Return for you at the moment. You will be able to book a Return once we collect your items.")) : null,
        React.createElement(ModuleError, { opened: isOneMoreServiceError && !isEmptyServiceError }, "Please choose one more service"),
        React.createElement(FlexItemsContainer, null, Object.entries(mainServices).map(([key, service]) => (React.createElement(ServiceItem, { Icon: service.iconUrl, title: service.title, isChecked: services.includes(key), onClick: () => onSetService(key), description: service.description, key: service.title }))))));
};
export default observer(Service);
