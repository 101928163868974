import React from 'react';
const CleaningIcon = ({ black, size }) => {
    const currentColor = black ? 'black' : 'white';
    const currentSize = size || 40;
    return (React.createElement("svg", { width: currentSize, height: currentSize, viewBox: `0 0 40 40`, fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M14.498 25.7936L9.70471 23.0262C8.74812 22.4739 8.42037 21.2507 8.97266 20.2941L17.9727 4.70568C18.5249 3.7491 19.7481 3.42135 20.7047 3.97363L36.3197 12.989C37.2659 13.5352 37.5986 14.7397 37.067 15.6941L32.998 22.9999", stroke: currentColor, strokeWidth: "2" }),
        React.createElement("path", { d: "M16 7.99988C15 5.99988 9.5 2.99988 4 5.99988", stroke: currentColor, strokeWidth: "2" }),
        React.createElement("path", { d: "M13 13.5981C11 10.5981 7.5 10.5981 4 11.5981", stroke: currentColor, strokeWidth: "2" }),
        React.createElement("path", { d: "M10 19C8.44444 16.6906 6 16.5 4 18", stroke: currentColor, strokeWidth: "2" }),
        React.createElement("path", { d: "M18.9982 38V32C17.1648 30 13.8986 25.6 13.4986 24C12.9986 22 15.4982 20 16.9982 22C18.1982 23.6 19.4983 25.3333 19.9983 26V17C19.9983 15.067 21.5653 13.5 23.4983 13.5V13.5M23.4983 13.5V12.75C23.4983 11.7835 24.2818 11 25.2483 11V11C26.2148 11 26.9983 11.7835 26.9983 12.75V14M23.4983 13.5V22M26.9983 14V14C28.7879 14 30.2498 15.4293 30.2902 17.2184L30.3079 18M26.9983 14V16V22M30.3079 18V18C31.7938 18 32.9983 19.2045 32.9983 20.6904V26C32.9983 27.3333 32.5983 30.4 30.9983 32V38M30.3079 18V22", stroke: currentColor, strokeWidth: "2" })));
};
export default CleaningIcon;
