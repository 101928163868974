import { Client } from 'getaddress-api';
import React, { useEffect, useRef, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { Input, InputMainWrapper } from '#Components/shared';
import { TEST_KEY } from '#Constants/apiKeys';
import useDebounce from '../../hooks/useDebounce';
const api = new Client(TEST_KEY);
const DropdownGetAddress = ({ onChange, error, value }) => {
    const [suggestions, setSuggestions] = useState([]);
    const [debouncedInputVal, inputValue, setInputValue] = useDebounce('', 500);
    const isSelected = useRef(false);
    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        setSuggestions([]);
    });
    const asyncSearch = async (val) => {
        if (isSelected.current) {
            return;
        }
        const autocompleteResult = await api.autocomplete(val);
        if (autocompleteResult.isSuccess) {
            const success = autocompleteResult.toSuccess();
            setSuggestions(success.suggestions);
        }
        else {
            const failed = autocompleteResult.toFailed();
            console.log(failed);
        }
    };
    const handleInput = (val) => {
        if (value) {
            onChange({ address: '', postcode: '' });
        }
        isSelected.current = false;
        setInputValue(val);
    };
    useEffect(() => {
        asyncSearch(debouncedInputVal);
    }, [debouncedInputVal]);
    const handleSelect = async (addr, id) => {
        isSelected.current = true;
        setInputValue(addr);
        setSuggestions([]);
        const addressResult = await api.get(id);
        if (addressResult.isSuccess) {
            const res = addressResult.toSuccess();
            onChange({ address: addr, postcode: res.address.postcode });
        }
        else {
            const failed = addressResult.toFailed();
            onChange({ address: addr, postcode: '' });
            console.log(failed);
        }
    };
    return (React.createElement("div", { ref: ref, className: "dropdown" },
        React.createElement(InputMainWrapper, { title: "Enter postcode" },
            React.createElement(Input, { value: value || inputValue, placeholder: "W1C 2PE", onChange: handleInput, errorText: suggestions.length ? '' : error })),
        !!suggestions.length && (React.createElement("div", { className: "dropdown__suggestions" }, suggestions
            .map(sg => ({ key: sg.id, value: sg.address }))
            .map(item => (React.createElement("div", { key: item.key, onClick: () => handleSelect(item.value, item.key), className: "dropdown__suggestion-item" }, item.value)))))));
};
export default DropdownGetAddress;
