import styled from 'styled-components';
export const DiscountContainer = styled.div `
  background: var(--main-color);
  border-radius: 70px;
  display: flex;
  margin-left: 16px;
  padding: 2px 8px;
  color: #fff;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  margin-left: auto;
  font-weight: 600;

  @media (max-width: 640px) {
    font-size: 12px;
    line-height: 16px;
    padding: 2px 4px;
    margin-left: 0;
    border-radius: 12px;
  }
`;
export const CardTitle = styled.div `
  font-weight: 600;
`;
export const ItemContainer = styled.div `
  max-width: 232px;
  min-height: 66px;
  width: 100%;
  display: flex;
  padding: 12px;
  align-items: center;
  cursor: pointer;
  border: 1px solid #cdd7e0;
  border-radius: 15px;

  @media (max-width: 640px) {
    max-width: 100%;
    min-height: 86px;

    max-width: 106px;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }

  ${({ isChecked }) => isChecked &&
    `
          background-color: #F0512D10;
          border: 1px solid var(--main-color);
    `}
`;
