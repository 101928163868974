import { StorageSpace12x8, StorageSpace3x5, StorageSpace6x4, StorageSpace6x6, StorageSpace8x6, StorageSpace9x8, StorageSpaceOther, } from '#Images';
import { CleaningIcon, MovingIcon, PackingIcon, StorageIcon } from '../components/icons';
import StorageReturnIcon from '#Components/icons/StorageReturnIcon';
export const StorageTermToNumber = {
    '3_months': 3 * 4,
    '6_months': 6 * 4,
    monthly: 1 * 4,
};
export const DiscountFromTerm = {
    monthly: 0,
    '3_months': 25,
    '6_months': 40,
};
export const collectionCostOfPlans = {
    wardrobe: 96,
    small_room: 149,
    studio_flat: 149,
    half_of_one_car_garage: 149,
    small_office: 196,
    standard_one_car_garage: 196,
    avg_house: 342,
};
export const getPromoPriceFromPlan = (key) => {
    const promoPrice = collectionCostOfPlans[key] - 50;
    return promoPrice >= 0 ? promoPrice : 0;
};
export const StorageTermKeysToTerm = {
    //amount of weeks
    monthly: 4,
    '3_months': 12,
    '6_months': 24,
};
export const cleaningMatching = {
    '1bed': 'one_bedroom',
    '2bed': 'two_bedroom',
    '3bed': 'three_bedroom',
};
export const storageMatching = {
    '15': 'wardrobe',
    '24': 'small_room',
    '36': 'studio_flat',
    '48': 'half_of_one_car_garage',
    '72': 'small_office',
    '96': 'standard_one_car_garage',
    Bigger: 'avg_house',
};
export const StorageSizes = {
    wardrobe: 'Plan 3x5',
    small_room: 'Plan 6x4',
    studio_flat: 'Plan 6x6',
    half_of_one_car_garage: 'Plan 8x6',
    small_office: 'Plan 9x8',
    standard_one_car_garage: 'Plan 12x8',
    avg_house: 'Custom',
};
export const cleaningPrices = {
    one_bedroom: {
        title: '1-bedroom',
        price: '£154',
        cleaners: 1,
        hours: 5,
        extraFee: 29,
    },
    two_bedroom: {
        title: '2-bedroom',
        price: '£299',
        cleaners: 2,
        hours: 5,
        extraFee: 59,
    },
    three_bedroom: {
        title: '3-bedroom',
        price: '£369',
        cleaners: 2,
        hours: 6,
        extraFee: 62,
    },
};
export const storageTerms = {
    monthly: { discount: 0, term: 0, title: 'Monthly' },
    '3_months': { discount: 25, term: 0, title: '3 months' },
    '6_months': { discount: 40, term: 0, title: '6 months' },
};
export const storageSpaces = {
    wardrobe: {
        iconUrl: StorageSpace3x5,
        title: 'Wardrobe',
        price: 14.99,
        sizes: '3 x 5 x 8 ft',
        sqft: '15',
    },
    small_room: {
        iconUrl: StorageSpace6x4,
        title: 'Small room',
        price: 22,
        sizes: '6 x 4 x 8 ft',
        sqft: '24',
    },
    studio_flat: {
        iconUrl: StorageSpace6x6,
        title: 'Studio flat',
        price: 29,
        sizes: '6 x 6 x 8 ft',
        sqft: '36',
    },
    half_of_one_car_garage: {
        iconUrl: StorageSpace8x6,
        title: '1.5 of a Garage',
        price: 39,
        sizes: '8 x 6 x 8ft',
        sqft: '48',
    },
    small_office: {
        iconUrl: StorageSpace9x8,
        title: 'Small Office',
        price: 58,
        sizes: '9 x 8 x 8 ft',
        sqft: '72',
    },
    standard_one_car_garage: {
        iconUrl: StorageSpace12x8,
        title: '1-Car Garage',
        price: 79,
        sizes: '12 x 8 x 8 ft',
        sqft: '96',
    },
    avg_house: {
        iconUrl: StorageSpaceOther,
        title: 'House+',
        price: 0.82,
        sizes: 'Any unit size',
        sqft: 'Bigger',
    },
};
// New Checkout
export var ServiceKeys;
(function (ServiceKeys) {
    ServiceKeys["MOVING"] = "MOVING";
    ServiceKeys["STORAGE"] = "STORAGE";
    ServiceKeys["SHIPPING"] = "SHIPPING";
    ServiceKeys["CLEANING"] = "CLEANING";
    ServiceKeys["PACKING"] = "PACKING";
    ServiceKeys["STORAGE_RETURN"] = "STORAGE_RETURN";
})(ServiceKeys || (ServiceKeys = {}));
export const postCodeKeysToTitle = {
    collection: 'collection',
    delivery: 'delivery',
    storage_collection: 'storage collection',
    cleaning: 'cleaning location',
};
export const postCodeKeysToFieldName = {
    collection: 'Collection postcode',
    delivery: 'Delivery postcode',
    storage_collection: 'Storage collection postcode',
    cleaning: 'Cleaning location postcode',
};
export const dateKeysToTitle = {
    movingDate: 'moving date',
    cleaningDate: 'cleaning date',
    storageCollectionDate: 'storage collection date',
    shippingDate: 'shipping date',
    deliveryDate: 'delivery date',
};
export const mainServices = {
    MOVING: {
        iconUrl: MovingIcon,
        title: 'Moving',
        description: 'Removal services in the UK',
    },
    STORAGE: {
        iconUrl: StorageIcon,
        title: 'Storage & Collection',
        description: 'Secure storage with collection',
    },
    CLEANING: {
        iconUrl: CleaningIcon,
        title: 'Cleaning',
        description: 'End-of-Tenancy cleaning',
    },
    PACKING: {
        iconUrl: PackingIcon,
        title: 'Packing',
        description: 'Comprehensive packing service',
    },
    // SHIPPING: {
    //   iconUrl: ShippingIcon,
    //   title: 'Shipping',
    //   description: 'International shipping and collection',
    // },
    STORAGE_RETURN: {
        iconUrl: StorageReturnIcon,
        title: 'Storage Return',
        description: 'Book your full or partial return',
    },
};
