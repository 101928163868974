import { makeAutoObservable } from 'mobx';
import { cleaningPrices, ServiceKeys, StorageSizes, StorageTermKeysToTerm, } from '#Types/newQuoteTypes';
import AppData from './AppData';
import dayjs from 'dayjs';
import { mailRegex } from '#Utils/validators';
import { getStringDateFromTimeSlot } from '#Utils/helpers';
class CheckoutData {
    currentSlide = 'service';
    totalSlides = 0;
    services = [];
    isServiceChanged = false;
    addresses = {
        collection: { address: '', postcode: '' },
        delivery: { address: '', postcode: '' },
        cleaning: { address: '', postcode: '' },
        storage_collection: { address: '', postcode: '' },
    };
    university = '';
    isSameAddressesChecked = false;
    isSameDateChecked = false;
    country = '';
    isServicePreSelected = false;
    isMovedFromService = false;
    storageSpace = null;
    storageTerm = null;
    cleaningSpace = null;
    dates = {
        movingDate: dayjs().add(7, 'day').toDate(),
        cleaningDate: dayjs().add(7, 'day').toDate(),
        storageCollectionDate: dayjs().add(7, 'day').toDate(),
        shippingDate: dayjs().add(7, 'day').toDate(),
        deliveryDate: dayjs().add(7, 'day').toDate(),
    };
    timeSlot = {
        movingDate: [0, 23],
        cleaningDate: [0, 23],
        storageCollectionDate: [0, 23],
        shippingDate: [0, 23],
        deliveryDate: [0, 23],
    };
    details = { email: '', phone: '', phonePrefix: '', firstName: '', lastName: '' };
    promo = '';
    signForNews = false;
    isAgreedWithTerms = false;
    isStudent = { value: 'false', label: 'No, I am not' };
    isStudentPromo = false;
    schoolEmail = '';
    utmParams = {};
    clientId = '';
    constructor() {
        makeAutoObservable(this);
    }
    get isPersonStudent() {
        return this.isStudent?.value === 'true';
    }
    get isAnyEmptyDate() {
        return Object.values(this.dates).some(date => !date);
    }
    get isStudentMailFilled() {
        return this.isStudent.value === 'true' ? mailRegex.test(this.schoolEmail) && !!this.schoolEmail : true;
    }
    get isMovingAndCollection() {
        return this.services.includes(ServiceKeys.MOVING) && this.services.includes(ServiceKeys.STORAGE);
    }
    get isStorageAndReturn() {
        return this.services.includes(ServiceKeys.STORAGE) && this.services.includes(ServiceKeys.STORAGE_RETURN);
    }
    get isShipping() {
        return this.services.includes(ServiceKeys.SHIPPING);
    }
    get isStorage() {
        return this.services.includes(ServiceKeys.STORAGE);
    }
    get isStorageReturn() {
        return this.services.includes(ServiceKeys.STORAGE_RETURN);
    }
    get isMoving() {
        return this.services.includes(ServiceKeys.MOVING);
    }
    get isCleaning() {
        return this.services.includes(ServiceKeys.CLEANING);
    }
    get isPacking() {
        return this.services.includes(ServiceKeys.PACKING);
    }
    get isOnlyCleaning() {
        return this.services.includes(ServiceKeys.CLEANING) && this.services.length === 1;
    }
    get isOnlyMoving() {
        return this.services.includes(ServiceKeys.MOVING) && this.services.length === 1;
    }
    get isOnlyStorage() {
        return this.services.includes(ServiceKeys.STORAGE) && this.services.length === 1;
    }
    get isOnlyCleaningAndPacking() {
        return this.isPacking && this.isCleaning && this.services.length === 2;
    }
    get isOnlyPackaging() {
        return this.services.includes(ServiceKeys.PACKING) && this.services.length === 1;
    }
    get isPlansNeeded() {
        return this.services.includes(ServiceKeys.CLEANING) || this.services.includes(ServiceKeys.STORAGE);
    }
    get nextSlide() {
        const current = this.currentSlide;
        if (current === 'plans')
            return 'dateAndTime';
        if (current === 'dateAndTime')
            return 'contactDetails';
        if (current === 'service' && !this.isPlansNeeded)
            return 'dateAndTime';
        return 'plans';
    }
    get prevSlide() {
        const current = this.currentSlide;
        if (current === 'contactDetails')
            return 'dateAndTime';
        if (current === 'dateAndTime' && !this.isPlansNeeded)
            return 'service';
        if (current === 'dateAndTime')
            return 'plans';
        return 'service';
    }
    // ? --
    get isTimeSlotsFilled() {
        return !this.services.length
            ? true
            : this.services.every(service => {
                if (service === ServiceKeys.STORAGE && this.timeSlot.storageCollectionDate.length)
                    return true;
                if (service === ServiceKeys.CLEANING && this.timeSlot.cleaningDate.length)
                    return true;
                if (service === ServiceKeys.MOVING && this.timeSlot.movingDate.length)
                    return true;
                if (service === ServiceKeys.SHIPPING ||
                    service === ServiceKeys.PACKING ||
                    service === ServiceKeys.STORAGE_RETURN)
                    return true;
                return false;
            });
    }
    get isDateFilled() {
        return !this.services.length
            ? true
            : this.services.every(service => {
                if (service === ServiceKeys.STORAGE &&
                    this.dates.storageCollectionDate &&
                    this.timeSlot.storageCollectionDate.length)
                    return true;
                if (service === ServiceKeys.CLEANING && this.dates.cleaningDate && this.timeSlot.cleaningDate.length)
                    return true;
                if (service === ServiceKeys.MOVING && this.dates.movingDate && this.timeSlot.movingDate.length)
                    return true;
                if (service === ServiceKeys.STORAGE_RETURN && this.dates.deliveryDate && this.timeSlot.deliveryDate.length)
                    return true;
                if (service === ServiceKeys.SHIPPING && this.dates.shippingDate && this.timeSlot.shippingDate.length)
                    return true;
                if (service === ServiceKeys.PACKING)
                    return true;
                return false;
            });
    }
    get isAddressFilled() {
        return !this.services.length
            ? true
            : this.services.every(service => {
                if (service === ServiceKeys.STORAGE && this.addresses.storage_collection.postcode)
                    return true;
                if (service === ServiceKeys.CLEANING && this.addresses.cleaning.postcode)
                    return true;
                if (service === ServiceKeys.MOVING && this.addresses.collection.postcode && this.addresses.delivery.postcode)
                    return true;
                if (service === ServiceKeys.STORAGE_RETURN && this.addresses.delivery.postcode)
                    return true;
                if (service === ServiceKeys.SHIPPING && this.addresses.collection.postcode && this.country)
                    return true;
                if (service === ServiceKeys.PACKING)
                    return true;
                return false;
            });
    }
    // --
    // ? ==
    get isPlansFilled() {
        return !this.services.length
            ? true
            : this.services.every(service => {
                if (service === ServiceKeys.STORAGE && this.storageSpace && this.storageTerm)
                    return true;
                if (service === ServiceKeys.CLEANING && this.cleaningSpace)
                    return true;
                if (service === ServiceKeys.SHIPPING ||
                    service === ServiceKeys.PACKING ||
                    service === ServiceKeys.STORAGE_RETURN ||
                    service === ServiceKeys.MOVING)
                    return true;
                return false;
            });
    }
    // ? ==
    setPromo = (promo) => {
        this.promo = promo;
    };
    setUniversity = (v) => {
        this.university = v;
    };
    setIsServiceChanged = (v) => {
        this.isServiceChanged = v;
    };
    setIsMovedFromService = (v) => {
        this.isMovedFromService = v;
    };
    setIsAgreed = (v) => {
        this.isAgreedWithTerms = v;
    };
    setSchoolEmail = (v) => {
        this.schoolEmail = v;
    };
    setIsSameAddressesChecked = (v) => {
        this.isSameAddressesChecked = v;
    };
    setIsSameDateChecked = (v) => {
        this.isSameDateChecked = v;
    };
    steIsServicePreSelected = (isPreSelected) => {
        this.isServicePreSelected = isPreSelected;
    };
    setCurrentSlide = (slide) => {
        this.currentSlide = slide;
    };
    setIsStudent = (isStudent) => {
        this.isStudent = isStudent;
        if (isStudent.value === 'false') {
            this.promo = '';
        }
        else {
            this.promo = 'STUDENTS';
        }
    };
    setIsStudentPromo = (isStudent) => {
        this.isStudentPromo = isStudent;
    };
    setTimeSlot = (timeSlot, k) => {
        this.timeSlot[k] = timeSlot;
    };
    setSignForNews = (sign) => {
        this.signForNews = sign;
    };
    setUtmParams = (obj) => {
        this.utmParams = obj;
    };
    setGtmClientId = (id) => {
        this.clientId = id;
    };
    setTotalSlides = (slides) => {
        this.totalSlides = slides;
    };
    setNextSlide = () => {
        this.currentSlide = this.nextSlide;
    };
    setPrevSlide = () => {
        this.currentSlide = this.prevSlide;
    };
    setService = (key) => {
        if (this.services.includes(key)) {
            this.services = this.services.filter(item => item !== key);
        }
        else {
            this.services.push(key);
        }
    };
    setAddress = (addr, key) => {
        this.addresses[key] = { postcode: addr.postcode, address: addr.address };
    };
    setCountry = (country) => {
        this.country = country;
    };
    setStorageSpace = (key) => {
        this.storageSpace = key;
    };
    setStorageTerm = (key) => {
        this.storageTerm = key;
    };
    setCleaningSpace = (key) => {
        this.cleaningSpace = key;
    };
    setDate = (date, key) => {
        this.dates[key] = date;
    };
    setDetails = (details) => {
        this.details = details;
    };
    sendData = () => {
        const allData = {
            ...(this.isMoving && {
                moving: {
                    collection_address: this.addresses.collection,
                    delivery_address: this.addresses.delivery,
                    datetime_slot: {
                        start: getStringDateFromTimeSlot(this.timeSlot.movingDate[0], this.dates.movingDate),
                        end: getStringDateFromTimeSlot(this.timeSlot.movingDate[1], this.dates.movingDate),
                    },
                },
            }),
            ...(this.isStorage &&
                this.storageSpace && {
                storage_collection: {
                    collection_address: this.addresses.storage_collection,
                    storage_plan: this.storageSpace && StorageSizes[this.storageSpace],
                    datetime_slot: {
                        start: getStringDateFromTimeSlot(this.timeSlot.storageCollectionDate[0], this.dates.storageCollectionDate),
                        end: getStringDateFromTimeSlot(this.timeSlot.storageCollectionDate[1], this.dates.storageCollectionDate),
                    },
                    storage_due_date: dayjs(this.dates.movingDate || this.dates.storageCollectionDate)
                        .add(StorageTermKeysToTerm[this.storageTerm || 'monthly'], 'week')
                        .toISOString()
                        .substring(0, 10),
                },
            }),
            ...(this.isCleaning && {
                cleaning: {
                    cleaning_address: this.addresses.cleaning,
                    cleaning_plan: {
                        amount_of_cleaners: this.cleaningSpace && cleaningPrices[this.cleaningSpace].cleaners,
                        hours: this.cleaningSpace && cleaningPrices[this.cleaningSpace].hours,
                    },
                    datetime_slot: {
                        start: getStringDateFromTimeSlot(this.timeSlot.cleaningDate[0], this.dates.cleaningDate),
                        end: getStringDateFromTimeSlot(this.timeSlot.cleaningDate[1], this.dates.cleaningDate),
                    },
                },
            }),
            ...(this.isPacking && { packing: true }),
            ...(this.isShipping && {
                shipping: {
                    collection_address: this.addresses.collection,
                    delivery_country: this.country,
                    datetime_slot: {
                        start: getStringDateFromTimeSlot(this.timeSlot.shippingDate[0], this.dates.shippingDate),
                        end: getStringDateFromTimeSlot(this.timeSlot.shippingDate[1], this.dates.shippingDate),
                    },
                },
            }),
            ...(this.isStorageReturn && {
                storage_return: {
                    delivery_address: this.addresses.delivery,
                    datetime_slot: {
                        start: getStringDateFromTimeSlot(this.timeSlot.deliveryDate[0], this.dates.deliveryDate),
                        end: getStringDateFromTimeSlot(this.timeSlot.deliveryDate[1], this.dates.deliveryDate),
                    },
                },
            }),
            person_info: {
                email: this.details.email,
                ...(this.schoolEmail && { students_email: this.schoolEmail }),
                phone: `+${this.details.phonePrefix}${this.details.phone}`,
                first_name: this.details.firstName,
                last_name: this.details.lastName,
                student: this.isPersonStudent,
            },
            ...(this.university && { university: this.university }),
            promo_code: this.promo || '',
            utm: this.utmParams,
            gta_client_id: this.clientId,
            promotions: this.signForNews,
        };
        return AppData.sendData(allData);
    };
}
export default new CheckoutData();
