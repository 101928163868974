import useStores from '#Hooks/useStores';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { AreYouStudent, PropertyType, StorageDuration, StoragePlan } from '../../modules';
import { HR } from '../../shared/Components';
import { NewEvents, trackNewEvent } from '#Utils/amplitudeAnalyticsService';
const Plans = () => {
    const { checkoutData: { isCleaning, isStorage, isStudentPromo }, } = useStores();
    useEffect(() => {
        trackNewEvent(NewEvents.checkoutv2_stage2_opened);
    }, []);
    return (React.createElement(React.Fragment, null,
        !isStudentPromo && (React.createElement(React.Fragment, null,
            React.createElement(AreYouStudent, null),
            React.createElement(HR, null))),
        isStorage && (React.createElement(React.Fragment, null,
            React.createElement(StorageDuration, null),
            React.createElement(HR, null),
            React.createElement(StoragePlan, null),
            isCleaning && React.createElement(HR, null))),
        isCleaning && React.createElement(PropertyType, null)));
};
export default observer(Plans);
