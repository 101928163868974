import { makeAutoObservable } from 'mobx';
import ApiService from '#Services/apiService';
import CheckoutData from './CheckoutData';
import { validateCredentials } from '#Utils/validators';
import { defaultErrorsObj } from '#Types/commonTypes';
const api = new ApiService();
class ErrorState {
    isEmptyDateError = {
        movingDate: false,
        cleaningDate: false,
        storageCollectionDate: false,
        shippingDate: false,
        deliveryDate: false,
    };
    isEmptyAddressError = {
        collection: false,
        delivery: false,
        cleaning: false,
        storage_collection: false,
    };
    isOneMoreServiceError = false;
    isEmptyCountryError = false;
    isEmptyServiceError = false;
    isNoStoragePlanError = false;
    isNoStorageTermError = false;
    isNoCleaningPropertyError = false;
    isStudentEmailError = false;
    detailErrors = defaultErrorsObj;
    constructor() {
        makeAutoObservable(this);
    }
    get isAnyDetailError() {
        const formErrors = validateCredentials(CheckoutData.details);
        return Object.values(formErrors).some(error => !!error);
    }
    clearErrorByDateKey = (dateKey) => {
        if (CheckoutData.timeSlot[dateKey].length && CheckoutData.dates[dateKey]) {
            this.isEmptyDateError[dateKey] = false;
        }
    };
    clearErrorByAddressKey = (addressKey) => {
        this.isEmptyAddressError[addressKey] = false;
    };
    setSchoolEmailError = () => {
        if (!CheckoutData.isStudentMailFilled) {
            this.isStudentEmailError = true;
        }
        else {
            this.isStudentEmailError = false;
        }
    };
    setEmptyDateErrors = () => {
        // TODO look for why CheckoutData.timeSlot doesn't parsed properly,
        const slots = Object.entries(CheckoutData.timeSlot).reduce((acc, [key, val]) => {
            acc[key] = val;
            return acc;
        }, {});
        Object.entries(CheckoutData.dates).forEach(([key, value]) => {
            if (!value || !slots[key].length) {
                this.isEmptyDateError[key] = true;
            }
            else {
                this.isEmptyDateError[key] = false;
            }
        });
    };
    setEmptyPlansErrors = () => {
        this.setEmptyStoragePlanError();
        this.setEmptyStorageTermError();
        this.setEmptyCleaningPlanError();
    };
    setEmptyStoragePlanError = () => {
        if (CheckoutData.isStorage && !CheckoutData.storageSpace) {
            this.isNoStoragePlanError = true;
        }
        else {
            this.isNoStoragePlanError = false;
        }
    };
    setEmptyStorageTermError = () => {
        if (CheckoutData.isStorage && !CheckoutData.storageTerm) {
            this.isNoStorageTermError = true;
        }
        else {
            this.isNoStorageTermError = false;
        }
    };
    setEmptyCleaningPlanError = () => {
        if (CheckoutData.isCleaning && !CheckoutData.cleaningSpace) {
            this.isNoCleaningPropertyError = true;
        }
        else {
            this.isNoCleaningPropertyError = false;
        }
    };
    setEmptyAddressErrors = () => {
        Object.entries(CheckoutData.addresses).forEach(([key, value]) => {
            if (!value.postcode) {
                this.isEmptyAddressError[key] = true;
            }
            else {
                this.isEmptyAddressError[key] = false;
            }
        });
    };
    setDetailsErrors = () => {
        const formErrors = validateCredentials(CheckoutData.details);
        this.detailErrors = formErrors;
    };
    setOneMoreServiceError = () => {
        if (CheckoutData.isOnlyPackaging) {
            this.isOneMoreServiceError = true;
        }
        else {
            this.isOneMoreServiceError = false;
        }
    };
    setEmptyCountryError = () => {
        this.isEmptyCountryError = !CheckoutData.country;
    };
    setEmptyServiceError = (value) => {
        this.isEmptyServiceError = value;
    };
    setNoStoragePlanError = (value) => {
        this.isNoStoragePlanError = value;
    };
    setNoStorageTermError = (value) => {
        this.isNoStorageTermError = value;
    };
    setNoCleaningPropertyError = (value) => {
        this.isNoCleaningPropertyError = value;
    };
}
export default new ErrorState();
