import allDomains from '#Constants/allDomains';
import * as Sentry from '@sentry/browser';
export const mailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i;
// eslint-disable-next-line no-useless-escape
const telRegex = /^(\+)[1-9][0-9\-\(\)\.]{6,15}$/i;
export const isEmailValid = (email) => {
    try {
        if (!mailRegex.test(email)) {
            return false;
        }
        const domain = email.split('@')[1].split('.')[1].toUpperCase();
        return allDomains.includes(domain);
    }
    catch (error) {
        console.log('Email validation error', error);
        Sentry.captureException(error);
        return false;
    }
};
export const isPhoneValid = (tel) => telRegex.test(tel);
export const validateCredentials = (creds) => {
    const errorObj = {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        phonePrefix: '',
        university: '',
        job_title: '',
    };
    const emptyFieldMessage = 'Please fill out all required fields';
    const wrongEmailMessage = 'Email is invalid';
    const wrongPhoneMessage = 'Please check is phone correct';
    if (!isEmailValid(creds.email)) {
        errorObj.email = wrongEmailMessage;
    }
    if (!isPhoneValid(`+${creds.phonePrefix}${creds.phone}`)) {
        errorObj.phone = wrongPhoneMessage;
    }
    Object.entries(creds).forEach(([key, cred]) => {
        if (!cred && key in errorObj) {
            errorObj[key] = emptyFieldMessage;
        }
    });
    return errorObj;
};
