/* eslint-disable import/extensions */
/* eslint-disable react/no-multi-comp */
import './formInput.scss';
import 'react-phone-number-input/style.css';
import React, { useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import InfiniteScroll from 'react-infinite-scroll-component';
import flags from 'react-phone-number-input/flags';
import { getCountries, getCountryCallingCode, } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { ArrowDown, SearchIcon } from '#Images/index';
const CountrySelect = ({ onClose, isOpen, onSelect, labels }) => {
    const [search, setSearch] = useState('');
    const wrapperRef = useOnclickOutside((e) => {
        onClose(e);
    });
    const onItemClick = (co) => {
        onSelect(co);
        onClose();
    };
    const filterData = (arr) => arr.filter(co => labels[co].toLocaleLowerCase().startsWith(search.toLocaleLowerCase()));
    const [preloadedItems, setPreloadedItems] = useState(getCountries().slice(0, 5));
    const fetchMoreData = () => {
        setPreloadedItems((prev) => Array.from(new Set([...filterData(prev), ...filterData(getCountries()).slice(prev.length + 1, prev.length + 5)])));
    };
    useEffect(() => {
        setPreloadedItems((prev) => Array.from(new Set([...filterData(prev), ...filterData(getCountries()).slice(0, 5)])));
    }, [search]);
    return (React.createElement(React.Fragment, null, isOpen &&
        React.createElement("div", { ref: wrapperRef, className: 'form-input-phone__select-list-wapper' },
            React.createElement("div", { className: 'form-input-phone__search' },
                React.createElement("img", { alt: 'search-icon', src: SearchIcon }),
                React.createElement("input", { value: search, onChange: (e) => setSearch(e.target.value), placeholder: 'Search...' })),
            React.createElement("div", { id: "Scroller", className: 'form-input-phone__list' },
                React.createElement(InfiniteScroll, { scrollableTarget: "Scroller", dataLength: preloadedItems.length, next: fetchMoreData, hasMore: true, loader: React.createElement(React.Fragment, null) }, preloadedItems.map((country) => {
                    const flag = flags[country];
                    const svgFlag = flag && flag({ title: '' });
                    return React.createElement("div", { onClick: () => onItemClick(country), className: "form-input-phone__list-item", key: country },
                        React.createElement("svg", { className: 'form-input-phone__list-item-flag' }, svgFlag),
                        `${labels[country]} (+${getCountryCallingCode(country)})`);
                }))))));
};
const CountryCodeSelect = ({ onChange, defaultCo = 'GB' }) => {
    const [prefix, setPrefix] = useState('');
    const [selectedCo, setSelectedCo] = useState(null);
    const [isCoListOpen, setIsCoListOpen] = useState(false);
    const setCountry = (co) => {
        const pfx = getCountryCallingCode(co);
        const flag = flags[co];
        const svgFlag = flag && flag({ title: '' });
        setPrefix(pfx);
        onChange(pfx);
        setSelectedCo(svgFlag);
    };
    useEffect(() => {
        setCountry('GB');
    }, []);
    const onCountryCodeClick = () => {
        setIsCoListOpen(!isCoListOpen);
    };
    return React.createElement("div", { className: 'form-input-phone' },
        React.createElement(CountrySelect, { onClose: () => setIsCoListOpen(false), isOpen: isCoListOpen, labels: en, onSelect: (co) => setCountry(co) }),
        React.createElement("div", { onClick: onCountryCodeClick, className: `form-input-phone__select ${isCoListOpen ? 'form-input-phone__select_open' : ''}` },
            React.createElement("svg", null, selectedCo),
            `+${prefix || getCountryCallingCode(defaultCo)}`,
            React.createElement("img", { alt: 'arrow-icon', src: ArrowDown })));
};
export default CountryCodeSelect;
