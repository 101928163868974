import { useEffect } from 'react';
function useEnterClick(foo) {
    const onKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
            foo();
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [foo]);
}
export default useEnterClick;
