import './final.scss';
import React from 'react';
import ButtonsBlock from '#Components/ButtonsBlock/ButtonsBlock';
const Final = () => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: "reminder" }, "Our operations team will contact you shortly. Please note that during busy periods like holidays, promotions and special campaigns, or non-working hours*, it may take a little longer to get back to you."),
    React.createElement("p", { className: "note" },
        "* We're available:",
        React.createElement("br", null),
        "Monday to Friday, 08:00 \u2013 20:00"),
    React.createElement(ButtonsBlock, { onNextClick: () => { } })));
export default Final;
