import useStores from '#Hooks/useStores';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Subtitle, Title } from '../../shared/Components';
import Country from './Country/Country';
const CountryModule = ({ kind }) => {
    const { checkoutData: { country, setCountry }, errorState: { isEmptyCountryError, setEmptyCountryError }, } = useStores();
    const onSetCountry = (v) => {
        setCountry(v);
        setEmptyCountryError();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null, `What is your delivery country`),
        React.createElement(Subtitle, null, 'Keep typing to display more results.'),
        React.createElement(Country, { title: "Shipping country", handleSelect: onSetCountry, country: country, description: `If you need to change the address for your order, you can do so with a minimum of 3 days' notice before your scheduled booking date.`, errorText: isEmptyCountryError ? 'Please select your shipping country' : '' })));
};
export default observer(CountryModule);
