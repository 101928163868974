import './buttonsBlock.scss';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '#Hooks/useStores';
import Button from '../Button/Button';
import { ButtonKinds } from '#Components/shared/Button/Button';
import { SocialsBlock } from '#Components';
import useEnterClick from '#Hooks/useEnterClick';
import { isMobile, isMobileSafari } from 'react-device-detect';
import { scrollToTop } from '#Utils/helpers';
import postMessageToWebflow from '#Utils/postMessageWebflow';
import { NewEvents, slideToBtnClickEvent, slideToBtnVisibleEvent, trackNewEvent, } from '#Utils/amplitudeAnalyticsService';
const ButtonsBlock = () => {
    const [buttonsIntersected, setButtonsIntersected] = useState([]);
    const { checkoutData, appData: { loading, error }, errorState, } = useStores();
    // TODO move to utils
    const scrollToError = () => {
        setTimeout(() => {
            const inputError = document.querySelector('#input-error');
            const moduleError = document.querySelector('#module-error');
            if (inputError) {
                if (isMobileSafari) {
                    const offset = inputError.getBoundingClientRect().top;
                    postMessageToWebflow(`${offset}`, 'error-scroll');
                }
                else {
                    inputError.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            }
            else if (moduleError) {
                if (isMobileSafari) {
                    const offset = moduleError.getBoundingClientRect().top;
                    postMessageToWebflow(`${offset}`, 'error-scroll');
                }
                else {
                    moduleError.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            }
        }, 170);
    };
    const onBackBtnClick = () => {
        checkoutData.setPrevSlide();
        scrollToTop();
    };
    const onNextBtnClick = () => {
        if (checkoutData.currentSlide === 'service' && checkoutData.isStorageAndReturn)
            return scrollToError();
        if (checkoutData.currentSlide === 'service' && !checkoutData.services.length)
            return errorState.setEmptyServiceError(true);
        if (checkoutData.currentSlide === 'service' && checkoutData.isOnlyPackaging)
            return errorState.setOneMoreServiceError();
        if (checkoutData.currentSlide === 'plans' && !checkoutData.isPlansFilled)
            return errorState.setEmptyPlansErrors(), scrollToError();
        if ((checkoutData.currentSlide === 'plans' || checkoutData.currentSlide === 'dateAndTime') &&
            !checkoutData.isStudentMailFilled)
            return errorState.setSchoolEmailError(), scrollToError();
        if (checkoutData.currentSlide === 'dateAndTime' &&
            (!checkoutData.isDateFilled ||
                !checkoutData.isAddressFilled ||
                (checkoutData.isShipping && !checkoutData.country))) {
            errorState.setEmptyDateErrors();
            errorState.setEmptyAddressErrors();
            errorState.setEmptyCountryError();
            scrollToError();
            return;
        }
        checkoutData.setIsMovedFromService(true);
        trackNewEvent(slideToBtnClickEvent[checkoutData.currentSlide]);
        if (checkoutData.isOnlyStorage || checkoutData.isOnlyMoving) {
            trackNewEvent(checkoutData.isOnlyStorage
                ? NewEvents.checkoutv2_only_storage_selected
                : NewEvents.checkoutv2_only_moving_selected);
        }
        checkoutData.setNextSlide();
        scrollToTop();
    };
    const isLastSlide = checkoutData.currentSlide === 'final';
    const isFinalSlide = checkoutData.currentSlide === 'contactDetails';
    const onSubmitClick = async () => {
        if (errorState.isAnyDetailError)
            return errorState.setDetailsErrors();
        await checkoutData.sendData();
        trackNewEvent(NewEvents.checkoutv2_stage4_submit_button_clicked);
    };
    const nextBtnRef = useRef(null);
    // any
    // TODO Move to somewhere
    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0, // Порог видимости элемента (от 0 до 1)
    };
    useEffect(() => {
        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (checkoutData.currentSlide === 'service' && !buttonsIntersected.includes('service')) {
                        trackNewEvent(slideToBtnVisibleEvent[checkoutData.currentSlide]);
                        setButtonsIntersected(prevState => [...prevState, 'service']);
                    }
                    if (checkoutData.currentSlide === 'plans' && !buttonsIntersected.includes('plans')) {
                        trackNewEvent(slideToBtnVisibleEvent[checkoutData.currentSlide]);
                        setButtonsIntersected(prevState => [...prevState, 'plans']);
                    }
                    if (checkoutData.currentSlide === 'dateAndTime' && !buttonsIntersected.includes('dateAndTime')) {
                        trackNewEvent(slideToBtnVisibleEvent[checkoutData.currentSlide]);
                        setButtonsIntersected(prevState => [...prevState, 'dateAndTime']);
                    }
                    if (checkoutData.currentSlide === 'contactDetails' && !buttonsIntersected.includes('contactDetails')) {
                        trackNewEvent(slideToBtnVisibleEvent[checkoutData.currentSlide]);
                        setButtonsIntersected(prevState => [...prevState, 'contactDetails']);
                    }
                }
            });
        };
        const observer = new IntersectionObserver(callback, options);
        if (nextBtnRef.current) {
            observer.observe(nextBtnRef.current);
        }
        return () => {
            if (nextBtnRef.current) {
                observer.unobserve(nextBtnRef.current);
            }
        };
    }, [checkoutData.currentSlide, buttonsIntersected.length]);
    useEnterClick(onNextBtnClick);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `button-block ${isLastSlide ? 'button-block_content-center' : ''}` }, !isLastSlide ? (React.createElement(React.Fragment, null,
            checkoutData.currentSlide !== 'service' && (React.createElement(Button, { full: true, style: { marginRight: isMobile ? 12 : 24 }, onClick: onBackBtnClick }, 'Back')),
            isFinalSlide ? (React.createElement(Button, { ref: nextBtnRef, full: true, type: "submit", disabled: !checkoutData.isAgreedWithTerms, loading: loading, onClick: onSubmitClick, kind: ButtonKinds.primary }, 'Submit')) : (React.createElement(Button, { ref: nextBtnRef, full: true, onClick: onNextBtnClick, kind: ButtonKinds.primary }, 'Next')))) : (React.createElement(React.Fragment, null,
            React.createElement("a", { target: "_top", href: "https://www.stackt.co.uk/" },
                React.createElement(Button, { onClick: () => { }, kind: ButtonKinds.primary }, 'Go to Homepage')),
            React.createElement(SocialsBlock, null))))));
};
export default observer(ButtonsBlock);
